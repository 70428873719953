import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { DesglosarCambioService } from '../../services/desglosar-cambio.service';
import { ConfigurationService } from 'src/app/core';

@Component({
  selector: 'ticketing-retirar-introducir',
  templateUrl: './retirar-introducir.component.html',
  styleUrls: ['./retirar-introducir.component.scss']
})
export class RetirarIntroducirComponent implements OnInit, OnDestroy {

  retirarForm: FormGroup;
  turno;
  cambio;
  caja;
  cambioResultante = 0;

  numeroCambioRetirar;
  numeroCambioIntroducir;

  myFormSub: Subscription;

  constructor(private formBuilder: FormBuilder,
              public turnoService: TurnoService,
              public configuration: ConfigurationService,
              private formControlService: FormControlService,
              private permisosService: PermisosService,
              private user: UserManagerService,
              private desgloseservice: DesglosarCambioService
              ) { }

  ngOnInit() {
    this.turnoService.turno.subscribe(turno => this.turno = turno);
    this.turnoService.cambio.subscribe( cambio => {
      this.cambio = cambio;
    });
    this.retirarForm = this.formBuilder.group({
      cambioRetirar: ['', Validators.compose([Validators.min(0), Validators.pattern('^[0-9]+(.[0-9]{0,2})?')])],
      cambioIntroducir: ['', Validators.compose([Validators.min(0), Validators.pattern('^[0-9]+(.[0-9]{0,2})?')])],
      comentarios: ['', Validators.maxLength(50)]
    });
    
    this.onChanges();
  }

  ngOnDestroy() {
    if(this.myFormSub) {this.myFormSub.unsubscribe();}
    this.formControlService.unfocus();
  }

  onChanges(): void {

    this.myFormSub = this.retirarForm.valueChanges.subscribe(val => {
      if (val) {
        this.numeroCambioRetirar = Number.parseFloat((val.cambioRetirar).replace(',', '.')) || 0;
        this.numeroCambioIntroducir = Number.parseFloat(val.cambioIntroducir.replace(',', '.')) || 0;
        const resultado = this.cambio.cambioResultante - this.numeroCambioRetirar + this.numeroCambioIntroducir;
        this.cambioResultante = resultado;
      }
    });
  }





actualizarImportes()
{ 
  this.turnoService.consultarEfectivoCaja().subscribe((res: any) => {
    this.cambio.ventasEfectivo = Number.parseFloat((res.DatosResult.ImporteTotalCaja || '0').replace('.', ','));
    this.turnoService.setCambioValue(res.DatosResult);
    this.cambioResultante = undefined;
  });
}
retirar(cantidad){
  return this.turnoService.retirarEfectivo(cantidad,this.retirarForm.controls['comentarios'].value)
  .pipe(map(res => {
      this.retirarForm.controls['cambioRetirar'].patchValue('');
      this.actualizarImportes();
    }));
}
introducir (cantidad){
  return this.turnoService.ingresarEfectivo(cantidad,this.retirarForm.controls['comentarios'].value).pipe(map(res => {
    this.retirarForm.controls['cambioIntroducir'].patchValue('');
    this.actualizarImportes();
}));
}

  aplicarOperacionDeCaja() {
    const cantidadIntroducir = this.numeroCambioIntroducir;
    const cantidadRetirar = this.numeroCambioRetirar;

    if(cantidadIntroducir > 0 && cantidadRetirar > 0)
    {     
      this.introducir(cantidadIntroducir)
      .subscribe( res =>{
        this.retirar(cantidadRetirar).subscribe();
      }
      );
    } else if (cantidadIntroducir > 0){
      this.introducir(cantidadIntroducir).subscribe();
    } else if (cantidadRetirar > 0){
      this.retirar(cantidadRetirar).subscribe();
    }

  }

  checkFormIsInvalid() {
    if (this.retirarForm.invalid) {
      return true;
    }
    return false;
  }

  focusValue(event: Event) {
    this.formControlService.setCurrentFormGroup(this.retirarForm, event.srcElement, event.srcElement.getAttribute('category'));
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
           element.id === this.formControlService.currentFormGroupValue.elemento.id ? true : false;
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false
    };
    return this.permisosService.puedeAccionar(this.user.currentUserValue, accion, conf);
  }
}
