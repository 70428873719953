import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { first, map, take, takeUntil, takeWhile } from 'rxjs/operators';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import {
  Carrito,
  EntradasService,
  StepperNavigationService,
  VentaService,
} from 'src/app/modules/venta';
import { MotivosService } from 'src/app/modules/venta/services/motivos.service';
import { RecintosService } from 'src/app/modules/venta/services/recintos.service';
import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { Reserva, ReservaRecinto } from '../../models/reserva.model';
import { LocalizadorService } from '../../services/localizador.service';
import { Subject } from 'rxjs';
import { TranslateService } from '../../../../shared/services/translate.service';

@Component({
  selector: 'ticketing-detalle-localizador',
  templateUrl: './detalle-localizador.component.html',
  styleUrls: ['./detalle-localizador.component.scss'],
  host: {
    class: 'layout',
  },
})
export class DetalleLocalizadorComponent implements OnInit {
  localizador;
  numeroPedido;
  carritoOrigen: Carrito;
  reserva: Reserva;
  listadoProductos = [];
  sePuedeAceptar;
  sePuedeImprimir;
  sePuedeFinalizar;
  idioma = 'es-ES';
  items;
  itemsConMotivo;
  divisaSimboloDecimal: string;
  divisaSimbolo: string;
  cantidadProductos = 0;
  totalReserva = 0;
  reimpresionDiaDistintoConfirmada;
  ventaReservaPagada;
  private _destroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private localizadorService: LocalizadorService,
    private router: Router,
    private ventaService: VentaService,
    private printService: PrintService,
    private turnoService: TurnoService,
    private entradaService: EntradasService,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private modalService: ModalService,
    private motivoService: MotivosService,
    private confirmationService: ConfirmationService,
    private stepperNavigation: StepperNavigationService,
    private configuracionService: ConfigurationService,
    private toast: ToastrService,
    private numpadService: NumpadService,
    private recintosService: RecintosService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.localizador = this.route.snapshot.paramMap.get('localizador');
    this.numeroPedido = this.route.snapshot.paramMap.get('numeroPedido');
    this.divisaSimboloDecimal =
      this.configuracionService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuracionService.divisasValue[0].simbolo;
    this.configuracionService.datosInicio.subscribe(
      (conf) => (this.idioma = conf.Idioma)
    );
    this.localizadorService
      .getDatosAdicionalesReserva(this.localizador)
      .subscribe((reserva: Reserva) => {
        this.localizadorService.setLocalizadorValue(reserva);
        this.reserva = reserva;
        this.reserva.NumeroPedido = this.numeroPedido;
        this.reserva.HoraEntrada = moment(reserva.FechaHoraEntrada).format(
          'HH:mm:ss'
        );
        this.cantidadProductos = 0;
        this.totalReserva = 0;

        this.cantidadProductos += Number.parseInt(reserva.NumEntradas, 10);
        this.totalReserva += Number.parseFloat(
          reserva.TotalPagar.replace(',', '.')
        );

        this.totalReserva = Number.parseFloat(this.totalReserva.toFixed(3));

        if (this.reserva.HoraEntrada === '23:59:59') {
          this.reserva.HoraEntrada = undefined;
        }
        this.sePuedeAceptar =
          this.reserva.Anulada === '0' &&
          this.reserva.NumVenta === '' &&
          this.reserva.Status != '06';
        this.sePuedeImprimir =
          this.reserva.Anulada === '0' &&
          this.reserva.Pagada === '1' &&
          this.reserva.NumVenta === '';
        if (this.configuracionService.maxNumImpresiones !== '0') {
          if (
            this.sePuedeImprimir === true &&
            reserva.ReservaItems &&
            reserva.ReservaItems.length !== 0 &&
            reserva.ReservaItems[0].ListaReimpresion.length !== 0 &&
            this.configuracionService.maxNumImpresiones <=
              reserva.ReservaItems[0].ListaReimpresion[0].NumReimpresiones
          ) {
            this.sePuedeImprimir = false;
            this.toast.error('', 'Limite de impresiones alcanzado', {
              toastComponent: ErrorToast,
              timeOut: 4000,
            });
          }
        }
        const conf = this.configuracionService.appConfig.value;
        const newDate = new Date(this.reserva.FechaHoraEntrada);
        const currentDate = new Date();
        //
        const permite =
          conf.DatosResult.AppConfigJsonLocalizador.ReservaPermitirAnteriores &&
          conf.DatosResult.AppConfigJsonLocalizador.ReservaPermitirAnteriores ==
            1
            ? true
            : currentDate > newDate
            ? false
            : true;
        this.sePuedeFinalizar =
          this.reserva.Anulada === '0' &&
          this.reserva.Pagada === '0' &&
          this.reserva.NumVenta === '' &&
          permite;
        this.mapEntradas().then((entradas) => {
          this.items = entradas;
          this.itemsConMotivo = entradas.filter(
            (linea) =>
              linea.entrada.GrupoMotivoId && linea.entrada.GrupoMotivoId !== ''
          );
        });
      });
  }

  getStatus(status: string) {
    switch (status) {
      case '00':
        return 'Anulada';
      case '01':
        return 'Pagada';
      case '02':
        return 'Pendiente';
      case '03':
        return 'Vencida';
      case '04':
        return 'Canjeada';
      case '05':
        return 'Devuelta';
      case '06':
        return 'Devuelta Parcialmente';
      default:
        return 'Pendiente';
    }
  }

  getFormaDePago(formaDePago: string) {
    switch (formaDePago) {
      case 'IN':
        return this.translate.instant('INTERNET');
      case 'CC':
        return this.translate.instant('TARJETA_CREDITO');
      case 'CA':
        return this.translate.instant('EFECTIVO');
      case 'TR':
        return this.translate.instant('TRANSFERENCIA');
      case 'TM':
        return this.translate.instant('TARJETA_MONEDERO'); // Yaribel 12112020 Añadimos la forma de pago  TM para que visualice el nombre de la forma de pago en gestionar venta
      case 'DP':
        return this.translate.instant('APLAZADO');
      case 'PR':
        return this.translate.instant('PREPAGO'); // Yaribel 20201217 añadimos forma de pago Prepago
      default:
        return '';
    }
  }

  getEvento(tipoAccion: string) {
    switch (tipoAccion) {
      case '00':
        return this.translate.instant('ANULADA');
      case '01':
        return this.translate.instant('PAGADA');
      case '02':
        return this.translate.instant('RESERVA');
      case '03':
        return this.translate.instant('MODIFICADO');
      case '04':
        return this.translate.instant('CANJEADA');
      case '05':
        return this.translate.instant('DEVUELTO');
      case '06':
        return this.translate.instant('DEVUELTA_TAQUILLA');
      case '07':
        return this.translate.instant('ACCESO_LOCALIZADOR');
      case '08':
        return this.translate.instant('ACCESO_VENTA_TAQUILLA');
      default:
        return '';
    }
  }

  async cargarEntradas() {
    this.agregarMotivos();
    if (!this.sePuedeAceptar) {
      return;
    }
    await this.cargarDatosLocalizador();
    this.ventaService.carritoValue.localizador = this.reserva;
    // this.ventaService.carritoValue.fecha = moment().format('DD/MM/YYYY HH:mm:ss');
    this.ventaService.carritoValue.reserva = {
      ClaveReserva: this.reserva.ClaveReserva,
      ReservaId: this.reserva.pkId,
    };
    this.stepperNavigation.continuarVenta.next(false);
    this.stepperNavigation.resetearPasos();
    if (this.reserva.Pagada == '1') {
      this.ventaReservaPagada = false;
      this.modalService.open('confirmarVentaReservaPagada');
      this.confirmationService.confirmado
        .pipe(takeWhile(() => !this.ventaReservaPagada))
        .subscribe((confirmado) => {
          if (confirmado) {
            this.router.navigate(['/venta/procedencia']);
          }
        });
    } else {
      this.router.navigate(['/venta/procedencia']);
    }
  }

  async modificarReserva() {
    if (!this.sePuedeFinalizar) {
      return;
    }
    this.stepperNavigation.continuarVenta.next(true);
    await this.cargarDatosLocalizador();
    this.ventaService.carritoValue.localizador = this.reserva;
    this.stepperNavigation.next(); //Yaribel 20210301 comprobamos si es esto lo que añade los datos a los recintos
    this.ventaService.carritoValue.fecha = moment().format(
      'YYYY/MM/DD HH:mm:ss'
    );
    this.ventaService.carritoValue.reserva = {
      ClaveReserva: this.reserva.ClaveReserva,
      ReservaId: this.reserva.pkId,
    };
    this.agregarListadoCaracteristicas(); //Yaribel Añadimos modificación de las entradas igual que se hace desde onInit de selecto-horarios.component.ts
    const newDate = new Date(this.reserva.FechaHoraEntrada);
    const currentDate = new Date();
    if (currentDate > newDate) {
      if (this.reserva.Agrupada !== '1') {
        this.modalService.open('confirmarCanjeoCaducado');
      } else {
        this.router.navigate(['/venta/procedencia'], {
          state: { fromButton: true },
        });
      }
    } else {
      this.router.navigate(['/venta/procedencia'], {
        state: { fromButton: true },
      });
    }
  }

  async finalizarVenta() {
    if (!this.sePuedeFinalizar) {
      return;
    }
    this.stepperNavigation.continuarVenta.next(true);
    await this.cargarDatosLocalizador();
    this.ventaService.carritoValue.localizador = this.reserva;
    this.stepperNavigation.next(); //Yaribel 20210301 comprobamos si es esto lo que añade los datos a los recintos
    this.ventaService.carritoValue.fecha = moment().format(
      'YYYY/MM/DD HH:mm:ss'
    );
    this.ventaService.carritoValue.reserva = {
      ClaveReserva: this.reserva.ClaveReserva,
      ReservaId: this.reserva.pkId,
    };
    this.agregarListadoCaracteristicas(); //Yaribel Añadimos modificación de las entradas igual que se hace desde onInit de selecto-horarios.component.ts
    const newDate = new Date(this.reserva.FechaHoraEntrada);
    const currentDate = new Date();
    if (currentDate > newDate) {
      if (this.reserva.Agrupada !== '1') {
        this.modalService.open('confirmarCanjeoCaducado');
      } else {
        this.router.navigate(['/venta/pago'], { state: { fromButton: true } });
      }
    } else {
      this.router.navigate(['/venta/pago'], { state: { fromButton: true } });
    }
  }

  private async cargarDatosLocalizador() {
    this.ventaService.clearCarrito();
    const entradas = await this.mapEntradas();
    // ;
    entradas.forEach((entrada) => {
      for (let index = 0; index < +entrada.entrada.cantidad; index++) {
        if (entrada.entrada.TipoProducto === '1') {
          //
          this.ventaService.sendEntrada(entrada.entrada);
          this.numpadService.entrada = entrada.entrada;
        } else if (entrada.entrada.TipoProducto === '2') {
          // ;
          const orderPromo = [entrada.entrada.NumOrdenPromos[index]];

          const tickets = entrada.entrada.PromocionEntradas.filter(function (
            e
          ) {
            const entradasVendidasPersonas = [];
            if (
              e.EntradasVendidasPersonas !== undefined &&
              e.EntradasVendidasPersonas.length === 1
            ) {
              entrada.entrada.PromocionEntradas.forEach((i) => {
                if (i.TipoEntradaId === e.TipoEntradaId) {
                  //
                  if (i.EntradasVendidasPersonas) {
                    entradasVendidasPersonas.push(
                      i.EntradasVendidasPersonas[0]
                    );
                  }
                }
              });
              e.EntradasVendidasPersonas = entradasVendidasPersonas;
            }
            if (e.NumOrdenPromo === orderPromo[0]) {
              return e.NumOrdenPromo === orderPromo[0];
            }
          });

          for (let i = 0; i < tickets.length; i++) {
            tickets[i].PrecioEnDivisaReferencia = tickets[i].PrecioUnidad;
            tickets[i].IVA = +tickets[i].IVA.replace(',', '.') + '';
          }

          const precioBono = Number(
            Number.parseFloat(
              entrada.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
            )
          ).toFixed(2);

          const producto = Object.assign({}, entrada.entrada, {
            NumOrdenPromosConEntradas:
              this.localizadorService.getNumPromosXEntrada(entrada),
            PromocionEntradas: tickets,
            PrecioEnDivisaReferencia: precioBono,
          });

          const entradaBase = Object.assign({}, entrada, { entrada: producto });
          //
          this.ventaService.sendEntrada(producto);
          this.numpadService.entrada = producto;
        }
      }
    });

    const cliente = this.mapCliente();
    const horarios = this.mapHorarios(entradas);
    const horariosPrevios = this.mapHorarios(entradas);
    this.ventaService.carritoValue.horarios = horarios;
    this.ventaService.carritoValue.horariosPrevios = horariosPrevios;
    this.ventaService.carritoValue.entradasPrevias = entradas;
    this.ventaService.setClienteSeleccionadoValue(cliente);
    this.ventaService.carritoValue.reservaPagada = this.reserva.Pagada;
    this.ventaService.carritoValue.reservaAgrupada = this.reserva.Agrupada; 
    this.ventaService.setFechaSeleccionadaValue(
      moment(this.ventaService.carritoValue.entradas[0].entrada.Fecha)
    );

    if (this.reserva.Pagada == '1' || this.reserva.Agrupada == '1') {
      this.ventaReservaPagada = this.reserva.Pagada == '1' ? true : false;

      if (this.reserva.Pagada == '1') {
        this.reserva.ReservaFOPs.forEach((FOP) => {
          this.ventaService.carritoValue.precioPrepago += +FOP.Importe.replace(
            ',',
            '.'
          );
        });
      } else {
        this.ventaService.carritoValue.precioPrepago =
          +this.reserva.Adelanto.replace(',', '.');
      }
      this.ventaService.carritoModificado = false;
      this.ventaService.carritoSubject
        .pipe(first())
        .subscribe((res1: Carrito) => {
          if (res1.carrito) {
            res1.setCarritoModificacionCarritoValue(res1.carrito);
            this.ventaService.carritoSubject.next(res1);
          }
        });
      this.carritoOrigen = JSON.parse(
        JSON.stringify(this.ventaService.carritoValue)
      );
      if (this.reserva.Pagada == '1') this.ventaService.clearCarrito();
      this.ventaService.setCarritoOrigen(this.carritoOrigen);
      this.ventaService.carritoOrigenValue.localizador = this.reserva;
      this.ventaService.carritoOrigenValue.reserva = {
        ClaveReserva: this.reserva.ClaveReserva,
        ReservaId: this.reserva.pkId,
      };
      //
      this.ventaService.setFechaSeleccionadaValue(
        moment(this.ventaService.carritoOrigenValue.entradas[0].entrada.Fecha) 
      );
    } else {
      this.ventaService.carritoValue.precioPrepago =
        +this.reserva.Adelanto.replace(',', '.');
    }
  }

  agregarMotivos() {
    if (this.itemsConMotivo && this.itemsConMotivo.length !== 0) {
      this.motivoService.setEntradasDesdeLocalizadorConMotivo(
        this.itemsConMotivo
      );
      this.modalService.open('motivosLocalizador');
    }
  }
  mapCliente() {
    return this.reserva.ReservaCliente[0];
  }
  async mapEntradas(): Promise<any[]> {
    let objCaracteristicas = [];
    const entradas: any = this.reserva.ReservaItems.map((producto, index) => {
      if (producto.PersonasReserva.length > 0) {
        if (producto.TipoProducto === '2') {
          let indice = 0;
          let entradaPrevia = 0;
          producto.PersonasReserva.forEach((item) => {
            let aux = index;
            if (producto.CuentaNominada == '0') {
              aux = 0;
            }
            if (producto.PersonasReserva[aux]) {
              objCaracteristicas.push({
                Tipo: producto.PersonasReserva[aux].NombreCentro,
                Rango: producto.PersonasReserva[aux].RangoEdad,
                Nivel: producto.PersonasReserva[aux].Curso,
                Profesor: '',
              });
            }
            if (item.TipoEntradaId == entradaPrevia) {
              indice = parseInt(item.numOrden, 10) - 1;
              entradaPrevia = item.TipoEntradaId;
              producto.PromocionEntradas[indice].EntradasVendidasPersonas.push(
                item
              );
            } else {
              objCaracteristicas = [];
              producto.PromocionEntradas[indice].EntradasVendidasPersonas = [
                item,
              ];
            }
          });
        } else {
          producto.PersonasReserva.forEach((item, indice) => {
            objCaracteristicas.push({
              Tipo: item.NombreCentro,
              Rango: item.RangoEdad,
              Nivel: item.Curso,
              Profesor: '',
            });
          });
        }
      }

      const entrada = {
        ...JSON.parse(JSON.stringify(producto)),
        idTarifa: producto.TarifaId,
        Modificado: false,
        TipoEntradaId: producto.TipoProducto === '1' ? producto.ItemId : '0',
        TipoPromocionId: producto.TipoProducto === '2' ? producto.ItemId : '0',
        TipoAbonadoId: '',
        Minimo: '',
        Maximo: '',
        Orden: '',
        PrecioEnDivisaReferencia: producto.PrecioUnidad,
        Fecha: producto.FechaHoraEntrada,
        cantidad: producto.Cantidad,
        CaracteristicasEntrada: objCaracteristicas,
        FechaHoraAccesoLocalizadorButaca:
          producto.ListaButacas.length > 0
            ? producto.ListaButacas[0].FechaHora
            : undefined,
        Listabutacas: producto.ListaButacas,
      };

      entrada.IVA = +producto.IVA.replace(',', '.') + '';

      if (
        producto.PersonasReserva.length > 0 &&
        producto.TipoProducto === '1'
      ) {
        entrada.EntradasVendidasPersonas = producto.PersonasReserva;
      }

      return {
        entrada: entrada,
        cantidad: Number.parseInt(producto.Cantidad, 10),
      };
    });
    const entradasIdsArr = [];
    entradas.forEach((entrada) => {
      if (entrada.entrada.TipoProducto === '2') {
        entrada.entrada.PromocionEntradas.forEach((item) => {
          entradasIdsArr.push(item.TipoEntradaId);
        });
      } else if (entrada.entrada.TipoProducto === '1') {
        entradasIdsArr.push(entrada.entrada.TipoEntradaId);
      }
    });
    const entradasIds = entradasIdsArr.join();
    return await this.entradaService
      .getRecintosEntrada(entradasIds)
      .pipe(
        map((res: any) => {
          if (res.DatosResult === null) {
            return entradas;
          }
          const recintos = res.DatosResult.RecintosDisponibles;
          entradas.forEach((e) => {
            if (e.entrada.TipoProducto === '1') {
              e.entrada.recintos = recintos.filter((recinto) => {
                return recinto.TipoEntrada === e.entrada.TipoEntradaId;
              });
            } else if (e.entrada.TipoProducto === '2') {
              e.entrada.recintos = [];
              e.entrada.PromocionEntradas.forEach((item) => {
                recintos
                  .filter((recinto) => {
                    return recinto.TipoEntrada === item.TipoEntradaId;
                  })
                  .forEach((ar) => {
                    e.entrada.recintos.push(ar);
                  });
              });
            }
            e.entrada.recintos = e.entrada.recintos.filter(
              (recinto, index, self) =>
                index ===
                self.findIndex(
                  (r) =>
                    r.TipoEntrada === recinto.TipoEntrada &&
                    r.Recinto === recinto.Recinto
                )
            );
          });
          return entradas;
        })
      )
      .toPromise();
  }

  reimprimirReserva() {
    if (!this.sePuedeImprimir) {
      return;
    }

    if (this.esDiaDistinto()) {
      //manieva duplicaba el modal
      //se reemplazo la bandera por una desuscripcion
      //this.reimpresionDiaDistintoConfirmada = false;
      this.modalService.open('confirmarReimpresionDiaDistinto');
      this.confirmationService.confirmado
        .pipe(takeUntil(this._destroy$))
        //.pipe(takeWhile(() => !this.reimpresionDiaDistintoConfirmada))
        .subscribe((confirmado) => {
          if (confirmado) {
            this.modalService.open('impresionLocalizador');
            this._destroy$.next();
          }
        });
      // }
    } else {
      this.modalService.open('impresionLocalizador');
    }
  }

  private printTickets() {
    this.printService.printOnlineTickets(
      this.turnoService.turnoValue.NombreTPV,
      this.reserva.ClaveReserva,
      `${this.reserva.FechaHoraEntrada}`,
      this.reserva.ReservaItems,
      this.localizadorService.localizadorValue
    );
  }

  private esDiaDistinto() {
    const fechaAlbaran = moment(
      this.localizadorService.localizadorReservaValue.FechaHoraEntrada,
      'YYYY/MM/DD HH:mm:ss'
    ).format('DD/MM/YYYY');
    const fechaHoy = moment().format('DD/MM/YYYY');
    return fechaAlbaran !== fechaHoy;
  }

  mapHorarios(entradas: any[]) {
    return this.ventaService.mapHorarios(this.reserva.ReservaRecintos);
  }

  conversor(precio: string) {
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false,
    };
    return this.permisosService.puedeAccionar(
      this.user.currentUserValue,
      accion,
      conf
    );
  }

  volver() {
    this.router.navigate(['/localizador']);
  }

  filtrarSolo1Promocion(entrada: any) {
    let respuesta = [];
    if (
      entrada &&
      entrada.PromocionEntradas &&
      entrada.PromocionEntradas.length > 0
    ) {
      respuesta = entrada.PromocionEntradas.filter(
        (promocion) => promocion.NumOrdenPromo == entrada.NumOrdenPromos[0]
      );
    }
    return respuesta;
  }

  //<<Yaribel 20210227  Comprobamos si la reserva tiene PosicionesVenta
  tienePosicionesVenta(reserva: any) {
    let tiene = '0';
    if (reserva.ReservaItems && reserva.ReservaItems.length > 0) {
      if (reserva.ReservaItems[0].TipoProducto == '2') {
        if (
          reserva.ReservaItems[0].PromocionEntradas &&
          reserva.ReservaItems[0].PromocionEntradas.length > 0 &&
          reserva.ReservaItems[0].PromocionEntradas[0].PosicionesVenta &&
          reserva.ReservaItems[0].PromocionEntradas[0].PosicionesVenta.length >
            0 &&
          +reserva.ReservaItems[0].PromocionEntradas[0].PosicionesVenta[0]
            .PosicionVenta > 0
        )
          tiene = '1';
      } else {
        //tiene = reserva.reservaItems[0].PosicionesVenta.find(x=> x.PosicionVenta > 0);
        if (
          reserva.ReservaItems[0].PosicionesVenta &&
          reserva.ReservaItems[0].PosicionesVenta.length > 0 &&
          +reserva.ReservaItems[0].PosicionesVenta[0].PosicionVenta > 0
        )
          tiene = '1';
      }
    }
    return tiene;
  }
  //Yaribel 20210227>>

  //<< Yaribel 20210227  añadimos una funcion con la modificacion que se hace de las entradas en el OnInit de selector-horarios.component.ts
  agregarListadoCaracteristicas() {
    let entradasmodificadas = [];
    this.ventaService.carritoValue.entradas.forEach((entrada) => {
      if (entrada.entrada.TipoProducto === '1') {
        if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0 &&
          this.recintosService.hayRecintosConAforoExterno(entrada.entrada)
        ) {
          entrada.entrada.CaracteristicasEntrada.forEach((item) => {
            this.listadoProductos.push(
              entrada.entrada.TipoProducto +
                ',' +
                entrada.entrada.TipoEntradaId +
                ',' +
                entrada.cantidad +
                ',,,,' +
                item.Tipo +
                ',' +
                item.Rango +
                ',' +
                item.Nivel
            );
          });
        } else if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0
        ) {
          //
          entrada.entrada.FiltrosCaracteristicas =
            entrada.entrada.CaracteristicasEntrada;
          entrada.entrada.CaracteristicasEntrada = null;
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoEntradaId +
              ',' +
              entrada.cantidad
          );
        } else {
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoEntradaId +
              ',' +
              entrada.cantidad
          );
        }
      } else if (entrada.entrada.TipoProducto === '2') {
        if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0 &&
          this.hayRecintosConAforoExterno(entrada.entrada)
        ) {
          entrada.entrada.CaracteristicasEntrada.forEach((item) => {
            this.listadoProductos.push(
              entrada.entrada.TipoProducto +
                ',' +
                entrada.entrada.TipoPromocionId +
                ',' +
                entrada.cantidad +
                ',,,,' +
                item.Tipo +
                ',' +
                item.Rango +
                ',' +
                item.Nivel
            );
          });
        } else if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0
        ) {
          //
          entrada.entrada.FiltrosCaracteristicas =
            entrada.entrada.CaracteristicasEntrada;
          entrada.entrada.CaracteristicasEntrada = null;
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoPromocionId +
              ',' +
              entrada.cantidad
          );
        } else {
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoPromocionId +
              ',' +
              entrada.cantidad
          );
        }
      }
      entradasmodificadas.push(entrada);
    });
    this.ventaService.carritoValue.entradas = entradasmodificadas;
  }

  hayRecintosConAforoExterno(entrada) {
    let respuesta = false;
    if (entrada && entrada.recintos && entrada.recintos.length > 0) {
      entrada.recintos.forEach((element) => {
        if (element.ControlAforoExterno && element.ControlAforoExterno != '')
          respuesta = true;
      });
    }
    return respuesta;
  }
  // Yaribel 20210227 >>

  filtrarRecintos(recintos) {
    const recintosFiltrados: ReservaRecinto[] = [];
    if (
      recintos &&
      recintos != undefined &&
      recintos != null &&
      recintos.length > 0
    ) {
      let index = 0;
      recintos.forEach((element) => {
        if (
          !recintosFiltrados.find(
            (x) =>
              x.RecintoId == element.RecintoId &&
              x.FechaEntrada == element.FechaEntrada &&
              x.EspectaculoId == element.EspectaculoId
          )
        ) {
          recintosFiltrados.push(element);
          recintosFiltrados[index].pkId = '';
          index++;
        }
      });
    }
    return recintosFiltrados;
  }

  formatearPrecio(precio) {
    if (typeof precio === 'string') {
      precio = Number.parseFloat(precio.replace(',', '.'));
    }
    return precio.toFixed(2);
  }
}
