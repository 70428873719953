import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { CardPaymentFactory } from 'src/app/core/hardware/card-payment/cardPayment.factory';
import { CardPaymentType } from 'src/app/core/hardware/card-payment/cardPaymentType.enum';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import * as xml2js from 'xml2js';
import { Albaran, AlbaranItem, DatosAlbaranTicketsProductos} from '../models/albaran.model';
import { DevolucionService } from './devolucion.service';
import { environment } from 'src/environments/pedrera/environment.development-pedrera';
import { TranslateService } from '../../../shared/services/translate.service';

@Injectable({
  providedIn: 'root'
})

export class AlbaranService {
  anulacionTotal = new BehaviorSubject(false);
  private albaranSubject: BehaviorSubject<Albaran>;
  public albaran: Observable<any>;

  private albaranesSubject: BehaviorSubject<any>;
  public albaranes: Observable<any>;

  public carritoItemsList = ['test', 'cosa', 'asdf'];

  public anularDeshabilitado = false;
  public facturarDeshabilitado = false;
  conf: any;


  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private turnoService: TurnoService,
    private devolucionService: DevolucionService,
    private configuration: ConfigurationService,
    private toast: ToastrService,
    private translate: TranslateService
  ) {
    this.albaranSubject = new BehaviorSubject(undefined);
    this.albaran = this.albaranSubject.asObservable();

    this.albaranesSubject = new BehaviorSubject(undefined);
    this.albaranes = this.albaranesSubject.asObservable();
  }

  public get albaranValue() {
    return this.albaranSubject.value;
  }

  setAlbaranValue(albaran) {
    this.albaranSubject.next(albaran);
  }

  public get albaranesValue() {
    return this.albaranSubject.value;
  }

  setAlbaranesValue(albaranes) {
    this.albaranesSubject.next(albaranes);
  }
  

  buscar(terms: Observable<any>) {
    return terms
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(input => this.getAlbaran(
        input.tpvId !== '' ? input.tpvId : this.configuration.datosTPVPathValue.pkId,
        '', (input.filtrarCentro? this.configuration.datosTPVPathValue.ClaveCentro : '' ),
        input.query
      )
      ));
  }

  buscar2(terms: Observable<any>) {
    return terms
      .pipe(debounceTime(400))
      .pipe(map(input => {
        return of(input);
      }))
      .pipe(switchMap((input: any) => this.getAlbaran(this.configuration.datosTPVPathValue.pkId, '',(input.filtrarCentro? this.configuration.datosTPVPathValue.ClaveCentro : '' ),input.value)));
  }

  anularAlbaranTarjeta(Identificador, NumAlbaran) {
    this.conf = this.configuration.appConfig.value;
    let body: any;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    if (this.albaranValue.PagoCCInfo.TieneLectorElectronico === 'False') {
      body = {
        ConexionIacpos: conexionId,
        VentaOrigenId: this.albaranValue.pkId,
        VentaOrigenTieneCC: this.albaranValue.TienePagoCC,
        Identificador: Identificador,
        NumAlbaran: NumAlbaran,
        // VentaOrigenImporteCC: this.albaranValue.AlbaranFOPs.find( fop => fop.FOP === 'CC').Importe,

        //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        // UsuarioID: this.albaranValue.UsuarioId,
        // TPVId: this.albaranValue.TPVId,
        UsuarioID: this.turnoService.turnoValue.UsuarioId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        TurnoId: this.turnoService.turnoValue.pkid,
        TPVId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        Facturado: this.albaranValue.Facturado,
        SerieFactura: this.albaranValue.SerieFactura,
        NumFacturaId: this.albaranValue.NumFacturaId,
        CambioRecibido: this.albaranValue.CambioRecibido.replace(',', '.'),
        CambioDevuelto: this.albaranValue.CambioDevuelto.replace(',', '.'),
        MantenerFechaOri: 0,
        Localizador: this.albaranValue.ClaveReserva,
        Fecha: this.albaranValue.FechaHoraEntrada,
        contadorAlbaranes: {
          // TpvId: this.albaranValue.TPVId
          TpvId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        },
        contadorOperacionesTarjeta: {
          // TpvId: this.albaranValue.TPVId
          TpvId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        },
        cajasTurno_cantidad: this.generarCajasTurnoCantidadAnulacion(),
        aforosVenta: this.generarAforosVenta(),
        resumenTurno: {
          TurnoId: this.turnoService.turnoValue.pkid,
          // UsuarioId: this.albaranValue.UsuarioId,
          // TPVId: this.albaranValue.TPVId,
          UsuarioId: this.turnoService.turnoValue.UsuarioId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
          TPVId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
          NumEntradas: Number.parseInt(this.albaranValue.NumEntradas, 10) * -1,
          IngresoAlbaranes:
            Number.parseFloat(this.albaranValue.TotalPagar.replace(',', '.')) *
            -1
        },
        OperacionesTarjeta: {
          FechaHora: '',
          Importe: this.albaranValue.AlbaranFOPs.find( fop => fop.FOP === 'CC').Importe,
          //this.devolucionService.carritoDevolucion.precioTotal,
          TipoOperacion: '02',
          NumAutorizacion2oBloque: '',
          CodigoAceptacion: 'AC',
          TextoInformativo: 'OPERACION ACEPTADA',
          TextoReservadoControlador: '',
          NumTarjeta: this.albaranValue.PagoCCInfo.NumTarjeta,
          FechaCaducidad: this.albaranValue.PagoCCInfo.FechaCaducidad,
          NumAutorizacion1erBloque: '',
          FechaConciliacion1erBloque: '',
          FechaConciliacion2oBloque: '',
          TieneLector: '0',
          LecturaElectronica: '0',
          PresenciaTitular: '1',
          TarjetaPresente: '1',
          TipoTarjeta: '',
          Identificador: Identificador,
          /*           NumOperacion: result.Operaciones.comunicacionContable[0]
            .resultadoComunicacion[0].pedido
            ? result.Operaciones.comunicacionContable[0]
              .resultadoComunicacion[0].pedido[0]
            : undefined,
          IdentEstablecimiento2oBloque: infoTarjeta.TarjetaMCPComercio,
          DatosAdicionales: result.Operaciones.comunicacionContable[0]
            .resultadoComunicacion[0].identificadorRTS
            ? result.Operaciones.comunicacionContable[0]
              .resultadoComunicacion[0].identificadorRTS[0]
            : undefined,
          DatosAdicionalesPrivados: infoTarjeta.TarjetaMCPTerminal, */
          CodigoAccion: '',
          PedidoBase: this.albaranValue.PagoCCInfo.NumOperacion,
          CodigoClienteID: '',
          NumReimpresiones: '',
          IntroduccionManual: '',
          DivisaId: ''
        }
      };

      return this.http
        .post(
          `${this.config.getConfig('API_URL')}api/VentaTaquilla/AnularVenta`,
          body
        )
        .pipe(
          map((res: any) => {
            if (!res.DatosResult) {
              this.toast.error('', res.Mensajes[0].DescripcionMensaje, {
                toastComponent: ErrorToast,
                timeOut: 5000
              });
              return {};
            }
            return res.DatosResult;
          })
        );
    } else if (this.albaranValue.PagoCCInfo.TieneLectorElectronico === 'True') {
      if (
        this.conf.DatosResult.AppConfigJsonTarjeta.TarjetaPeriferico === 'D'
      ) {
        this.toast.error(this.translate.instant('ERROR_NO_LECTOR_TARJETA'), 'Error', {
          toastComponent: ErrorToast
        });
        return;
      }
      const cardPaymentConnection = CardPaymentFactory.getCardPayment(
        CardPaymentType.PINPAD
      );
      const infoTarjeta = this.configuration.deviceConfiguration
        .AppConfigJsonTarjeta;
      try {
        const respuestaPagoVisa = cardPaymentConnection.pay(
          this.pad(this.configuration.datosTPVPathValue.pkId, 4),
          '2',
          '0',
          NumAlbaran,
          Identificador,
          'A',
          infoTarjeta.TarjetaTipoComunicacion,
          '0',
          this.albaranValue.AlbaranFOPs.find( fop => fop.FOP === 'CC').Importe,//.albaranValue.TotalPagar,
          this.albaranValue.TPVId,
          this.albaranValue.PagoCCInfo.NumTarjeta,
          this.albaranValue.PagoCCInfo.FechaCaducidad,
          this.albaranValue.PagoCCInfo.NumOperacion,
          this.albaranValue.PagoCCInfo.DatosAdicionales,
          infoTarjeta.TarjetaMCPTerminal,
          infoTarjeta.TarjetaMCPVersion,
          infoTarjeta.TarjetaMCPComercio,
          infoTarjeta.TarjetaMCPClave,
          infoTarjeta.TarjetaMCPPuertoCOM
        );
        const [tipoRespuesta, ...rest] = respuestaPagoVisa.split(':');
        const respuestaXml = rest.join();
        const that = this;
        if (tipoRespuesta === 'OK') {
          xml2js.parseString(respuestaXml, function(err, result) {
            if (err !== null) {
              this.toast.success('Venta anulada', 'OK', {
                toastComponent: ErrorToast
              });
              return;
            }
            body = {
              ConexionIacpos: conexionId,
              VentaOrigenId: that.albaranValue.pkId,
              VentaOrigenTieneCC: that.albaranValue.TienePagoCC,
              Identificador: Identificador,
              NumAlbaran: NumAlbaran,
              // VentaOrigenImporteCC: this.albaranValue.AlbaranFOPs.find( fop => fop.FOP === 'CC').Importe,

              //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
              // UsuarioID: that.albaranValue.UsuarioId,
              // TPVId: that.albaranValue.TPVId,
              UsuarioID:that.turnoService.turnoValue.UsuarioId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
              TurnoId: that.turnoService.turnoValue.pkid,
              TPVId: that.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
              Facturado: that.albaranValue.Facturado,
              SerieFactura: that.albaranValue.SerieFactura,
              NumFacturaId: that.albaranValue.NumFacturaId,
              CambioRecibido: that.albaranValue.CambioRecibido.replace(
                ',',
                '.'
              ),
              CambioDevuelto: that.albaranValue.CambioDevuelto.replace(
                ',',
                '.'
              ),
              MantenerFechaOri: 0,
              Localizador: that.albaranValue.ClaveReserva,
              Fecha: that.albaranValue.FechaHoraEntrada,
              contadorAlbaranes: {
                // TpvId: that.albaranValue.TPVId
                TPVId: that.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
              },
              contadorOperacionesTarjeta: {
                // TpvId: that.albaranValue.TPVId
                TPVId: that.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
              },
              cajasTurno_cantidad: that.generarCajasTurnoCantidadAnulacion(),
              aforosVenta: that.generarAforosVenta(),
              resumenTurno: {
                 TurnoId: that.turnoService.turnoValue.pkid,
                // UsuarioId: that.albaranValue.UsuarioId,
                // TPVId: that.albaranValue.TPVId,
                UsuarioId: that.turnoService.turnoValue.UsuarioId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
                TPVId: that.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
                NumEntradas:
                  Number.parseInt(that.albaranValue.NumEntradas, 10) * -1,
                IngresoAlbaranes:
                  Number.parseFloat(
                    that.albaranValue.TotalPagar.replace(',', '.')
                  ) * -1
              },
              OperacionesTarjeta: {
                FechaHora: '',
                Importe: that.albaranValue.AlbaranFOPs.find( fop => fop.FOP === 'CC').Importe,//.albaranValue.TotalPagar,
                TipoOperacion: '02',
                NumAutorizacion2oBloque: '',
                CodigoAceptacion: 'AC',
                TextoInformativo: 'OPERACION ACEPTADA',
                TextoReservadoControlador: '',
                NumTarjeta: that.albaranValue.PagoCCInfo.NumTarjeta,
                FechaCaducidad: that.albaranValue.PagoCCInfo.FechaCaducidad,
                NumAutorizacion1erBloque: '',
                FechaConciliacion1erBloque: '',
                FechaConciliacion2oBloque: '',
                TieneLector: '1',
                LecturaElectronica: '1',
                PresenciaTitular: '1',
                TarjetaPresente: '1',
                TipoTarjeta: '',
                Identificador: Identificador,
                /*NumOperacion: result.Operaciones.comunicacionContable[0]
                  .resultadoComunicacion[0].pedido
                  ? result.Operaciones.comunicacionContable[0]
                      .resultadoComunicacion[0].pedido[0]
                  : undefined,
                IdentEstablecimiento2oBloque: infoTarjeta.TarjetaMCPComercio,
                DatosAdicionales: result.Operaciones.comunicacionContable[0]
                  .resultadoComunicacion[0].identificadorRTS
                  ? result.Operaciones.comunicacionContable[0]
                      .resultadoComunicacion[0].identificadorRTS[0]
                  : undefined,
                DatosAdicionalesPrivados: infoTarjeta.TarjetaMCPTerminal,*/
                CodigoAccion: '',
                PedidoBase: that.albaranValue.PagoCCInfo.NumOperacion,
                CodigoClienteID: '',
                NumReimpresiones: '',
                IntroduccionManual: '',
                DivisaId: ''
              }
            };
          });
          return this.http
            .post(
              `${this.config.getConfig(
                'API_URL'
              )}api/VentaTaquilla/AnularVenta`,
              body
            )
            .pipe(
              map((res: any) => {
                if (!res.DatosResult) {
                  this.toast.error('', res.Mensajes[0].DescripcionMensaje, {
                    toastComponent: ErrorToast,
                    timeOut: 5000
                  });
                  return {};
                }
                return res.DatosResult;
              })
            );
        }
        if (tipoRespuesta === 'KO') {
          xml2js.parseString(respuestaXml, (err, result) => {
            if (err) {
              that.toast.error(`[${err}`, 'Error', {
                toastComponent: ErrorToast
              });
              return;
            }
            that.toast.error(
              `[${result.Error[0].codigo[0]}] ${result.Error[0].mensaje[0]}`,
              'Error',
              { toastComponent: ErrorToast }
            );
            return;
          });
        }
        if (tipoRespuesta === 'ERR') {
          this.toast.error(respuestaXml, 'Error', {
            toastComponent: ErrorToast
          });
          return;
        }
      } catch (e) {
        console.error(e);
        this.toast.error(this.translate.instant('ERROR_INESPERADO'), 'Error', {
          toastComponent: ErrorToast
        });
        return;
      }
    }
  }

  async anularAlbaran() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      VentaOrigenId: this.albaranValue.pkId,
      VentaOrigenTieneCC: this.albaranValue.TienePagoCC,
      // VentaOrigenImporteCC: this.albaranValue.AlbaranFOPs.find( fop => fop.FOP === 'CC').Importe,

      //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
      // UsuarioID: this.albaranValue.UsuarioId,
      // TPVId: this.albaranValue.TPVId,
      TurnoId: this.turnoService.turnoValue.pkid,
      UsuarioID: this.turnoService.turnoValue.UsuarioId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
      TPVId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
      Facturado: this.albaranValue.Facturado,
      SerieFactura: this.albaranValue.SerieFactura,
      NumFacturaId: this.albaranValue.NumFacturaId,
      CambioRecibido: 0.00,
      CambioDevuelto: 0.00,
      MantenerFechaOri: 0,
      Localizador: this.albaranValue.ClaveReserva,
      Fecha: this.albaranValue.FechaHoraEntrada,
      contadorAlbaranes: {
        // TpvId: this.albaranValue.TPVId
        TpvId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api

      },
      contadorOperacionesTarjeta: {
        // TpvId: this.albaranValue.TPVId
        TpvId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
      },
      cajasTurno_cantidad: this.generarCajasTurnoCantidadAnulacion(),
      aforosVenta: this.generarAforosVenta(),
      resumenTurno: {
        TurnoId: this.turnoService.turnoValue.pkid,
        // UsuarioId: this.albaranValue.UsuarioId,
        // TPVId: this.albaranValue.TPVId,
        UsuarioId: this.turnoService.turnoValue.UsuarioId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        TPVId: this.turnoService.turnoValue.TpvId, //Yaribel 20201126  Modificamos la inicialización tal como solicita la documentacion del api
        NumEntradas: Number.parseInt(this.albaranValue.NumEntradas, 10) * -1,
        IngresoAlbaranes:
          Number.parseFloat(this.albaranValue.TotalPagar.replace(',', '.')) * -1
      }
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/AnularVenta`,
        body
      )
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.toast.error('', res.Mensajes[0].DescripcionMensaje, {
              toastComponent: ErrorToast,
              timeOut: 5000
            });
            return {};
          }
          return res.DatosResult;
        })
      )
      .toPromise();
  }

  // TODO
  async devolucionParcial(entradas) {
    const body = this.generarPeticionDevolucion(entradas);
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsercionVenta`,
        body
      ).pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.toast.error('', res.Mensajes[0].DescripcionMensaje, {
              toastComponent: ErrorToast,
              timeOut: 5000
            });
            return {};
          }
          return res;
        })
      ).toPromise();
  }

  devolucionParcialTarjeta(entradas, Identificador, NumAlbaran) {
    this.conf = this.configuration.appConfig.value;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    let body: any;
    if (this.albaranValue.PagoCCInfo.TieneLectorElectronico === 'False') {
      body = this.generarPeticionDevolucion(
        entradas,
        Identificador,
        NumAlbaran,
        '',
        '',
        '',
        ''
      );
      body.OperacionesTarjeta = {
        FechaHora: '',
        Importe: this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1' || this.albaranValue.AlbaranFOPs.length < 2 ? 
        this.devolucionService.carritoDevolucion.precioTotal :  this.devolucionService.generarMediosPagoVenta()
        .find(fop => fop.FOP === 'CC').Importe * (-1),
        TipoOperacion: '02',
        NumAutorizacion2oBloque: '',
        CodigoAceptacion: 'AC',
        TextoInformativo: 'OPERACION ACEPTADA',
        TextoReservadoControlador: '',
        NumTarjeta: this.albaranValue.PagoCCInfo.NumTarjeta,
        FechaCaducidad: this.albaranValue.PagoCCInfo.FechaCaducidad,
        NumAutorizacion1erBloque: '',
        FechaConciliacion1erBloque: '',
        FechaConciliacion2oBloque: '',
        Identificador: Identificador,
        TieneLector: '0',
        LecturaElectronica: '0',
        PresenciaTitular: '1',
        TarjetaPresente: '1',
        TipoTarjeta: '',
        CodigoAccion: '',
        PedidoBase: '',
        CodigoClienteID: '',
        NumReimpresiones: '',
        IntroduccionManual: '',
        DivisaId: ''
      };
      return this.http.post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsercionVenta`,
        body
      ).pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.toast.error('', res.Mensajes[0].DescripcionMensaje, {
              toastComponent: ErrorToast,
              timeOut: 5000
            });
            return {};
          }
          return res;
        })
      );
    } else if (this.albaranValue.PagoCCInfo.TieneLectorElectronico === 'True') {
      if (
        this.conf.DatosResult.AppConfigJsonTarjeta.TarjetaPeriferico === 'D'
      ) {
        this.toast.error('No hay lector de tarjeta', 'Error', {
          toastComponent: ErrorToast
        });
        return;
      }
    }
    const cardPaymentConnection = CardPaymentFactory.getCardPayment(
      CardPaymentType.PINPAD
    );
    const infoTarjeta = this.configuration.deviceConfiguration
      .AppConfigJsonTarjeta;
    try {
      const respuestaPagoVisa = cardPaymentConnection.pay(
        this.pad(this.configuration.datosTPVPathValue.pkId, 4),
        '2',
        '0',
        NumAlbaran,
        Identificador,
        'D',
        infoTarjeta.TarjetaTipoComunicacion,
        '0',
        //this.albaranValue.TotalPagar,
        //this.devolucionService.carritoDevolucion.precioTotal.toString(),
        (''+ (this.devolucionService.pagoDesglosadoValue.pinpad + this.devolucionService.pagoDesglosadoValue.datafono)).replace('.', ','),
        this.albaranValue.TPVId,
        this.albaranValue.PagoCCInfo.NumTarjeta,
        this.albaranValue.PagoCCInfo.FechaCaducidad,
        this.albaranValue.PagoCCInfo.NumOperacion,
        this.albaranValue.PagoCCInfo.DatosAdicionales,
        infoTarjeta.TarjetaMCPTerminal,
        infoTarjeta.TarjetaMCPVersion,
        infoTarjeta.TarjetaMCPComercio,
        infoTarjeta.TarjetaMCPClave,
        infoTarjeta.TarjetaMCPPuertoCOM
      );
      const [tipoRespuesta, ...rest] = respuestaPagoVisa.split(':');
      const respuestaXml = rest.join();
      const that = this;
      if (tipoRespuesta === 'OK') {
        //this.toast.error('Todo OK', 'OK', { toastComponent: ErrorToast });
        xml2js.parseString(respuestaXml, function(err, result) {
          if (err !== null) {
            // this.toast.error('Todo OK', 'OK', {
            //   toastComponent: ErrorToast
            // });
            return;
          }
          body = that.generarPeticionDevolucion(
            entradas,
            Identificador,
            NumAlbaran,
            // result.Operaciones.comunicacionContable[0].resultadoComunicacion[0]
            //   .pedido[0],
            // result.Operaciones.comunicacionContable[0].resultadoComunicacion[0]
            //   .identificadorRTS[0],
            undefined,
            undefined,
            infoTarjeta.TarjetaMCPComercio,
            infoTarjeta.TarjetaMCPTerminal
          );
        });
        return this.http.post(
          `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsercionVenta`,
          body
        ).pipe(
          map((res: any) => {
            if (!res.DatosResult) {
              this.toast.error('', res.Mensajes[0].DescripcionMensaje, {
                toastComponent: ErrorToast,
                timeOut: 5000
              });
              return {};
            }
            return res;
          })
        );
      }
      if (tipoRespuesta === 'KO') {
        xml2js.parseString(respuestaXml, (err, result) => {
          if (err) {
            that.toast.error(`[${err}`, 'Error', {
              toastComponent: ErrorToast
            });
            return;
          }
          that.toast.error(
            `[${result.Error[0].codigo[0]}] ${result.Error[0].mensaje[0]}`,
            'Error',
            { toastComponent: ErrorToast }
          );
          return;
        });
      }
      if (tipoRespuesta === 'ERR') {
        this.toast.error(respuestaXml, 'Error', { toastComponent: ErrorToast });
        return;
      }
    } catch (e) {
      console.error(e);
      this.toast.error(this.translate.instant('ERROR_INESPERADO'), 'Error', {
        toastComponent: ErrorToast
      });
      return;
    }
  }

  getUltimoAlbaran(idTpv) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: idTpv,
      ClaveCentro: this.configuration.appConfig.value.DatosResult.AppConfigJsonVentaGestionar.FiltrarCentro ? this.configuration.datosTPVPathValue.ClaveCentro: '',
      NumAlbaran: '',
      Pagina: '1',
      NumeroRegistros: '1'
    };
    this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .pipe(
        map((res: any) => {
          if (res.DatosResult === null) {
            this.setAlbaranValue({ noData: true });
            return;
          }

          //<<Yaribel 20210217  Añadimos filtro para que no se nos dupliquen las entradas
          if(res.DatosResult && res.DatosResult.ListadoAlbaranInfo[0] && res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems)
          {
            let newAlbaranItems: AlbaranItem[] = [];
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems.forEach((element) => {
              let datos: AlbaranItem = newAlbaranItems.find(item => item.ItemId === element.ItemId && item.TipoProducto === element.TipoProducto && item.PrecioUnidad === element.PrecioUnidad);
              if(datos === null || datos === undefined){
                  const nuevo = {...element, Contactos:[]};
                  nuevo.Contactos.push(element.idContacto);
                  newAlbaranItems.push(nuevo);

              }else{
                datos.Cantidad = (+datos.Cantidad + +element.Cantidad) + '';
                datos.CantidadMaxima = (+datos.CantidadMaxima + +element.CantidadMaxima) + '';
                //datos.PrecioTotal = (+datos.PrecioTotal + +element.PrecioTotal) + '';     
                datos.PrecioTotal = (Number.parseFloat(datos.PrecioTotal.replace(',', '.')) + Number.parseFloat(element.PrecioTotal.replace(',', '.'))) + '';     
                //Number.parseFloat(entrada.PrecioTotal.replace(',', '.'))           
              }
            });
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems = newAlbaranItems;
          }
          // Yaribel 20210217 >>
          this.setAlbaranValue({
            ...res.DatosResult.ListadoAlbaranInfo[0],
            noData: false,
            NombreTPV: this.turnoService.turnoValue.NombreTPV,
            IdTPV: this.turnoService.turnoValue.TpvId // Yaribel 20210315 Añadimos el pkid del tpv actual
          });
        })
      )
      .subscribe();
  }
 
  getDatosAlbaranTicketsProductos(albaranId: string, numTicket?: string) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const token = JSON.parse(localStorage.getItem('currentToken'));
    const id = localStorage.getItem('credentialId');
    //UNO O EL OTRO
    const body = {
      ConexionIacpos: conexionId,
      ClienteApi: {
        Id: id,
        Token: token.token
      },
      Identificador: this.albaranValue.NumVenta,
      AlbaranId: this.albaranValue.AlbaranFOPs[0].AlbaranId
    };
    //NumTicket: numTicket
    return this.http
        .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaranTicketsProductos`, body)
        .pipe( map((res: any) => {
          return res.DatosResult as DatosAlbaranTicketsProductos;
        }));
  }



  datosAlbaran(numAlbaran)
  {
    return this.datosAlbaranConTPVId(numAlbaran, this.albaranValue.TPVId);
  }

  datosAlbaranConTPVId(numAlbaran, TPVId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      // TPVId: this.albaranValue.TPVId,
      TPVId: TPVId,
      NumAlbaran: numAlbaran,
      Pagina: '1',
      NumeroRegistros: '1'
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .pipe(
        map((res: any) => {
          if (res) {
            //<<Yaribel 20210217  Añadimos filtro para que no se nos dupliquen las entradas
            if(res.DatosResult && res.DatosResult.ListadoAlbaranInfo[0] && res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems)
            {
              let newAlbaranItems: AlbaranItem[] = [];
              res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems.forEach((element) => {
                let datos: AlbaranItem = newAlbaranItems.find(item => item.ItemId === element.ItemId && item.TipoProducto === element.TipoProducto && item.PrecioUnidad === element.PrecioUnidad);
                if(datos === null || datos === undefined){
                    const nuevo = {...element, Contactos:[]};
                    nuevo.Contactos.push(element.idContacto);
                    newAlbaranItems.push(nuevo);
  
                }else{
                  datos.Cantidad = (+datos.Cantidad + +element.Cantidad) + '';
                  datos.CantidadMaxima = (+datos.CantidadMaxima + +element.CantidadMaxima) + '';
                  datos.PrecioTotal = (+datos.PrecioTotal + +element.PrecioTotal) + '';
                }
              });
              res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems = newAlbaranItems;
            }
            // Yaribel 20210217 >>
            return res;
          }
        })
      );
  }

  refrescarAlbaran(numAlbaran) {
    return this.refrescarAlbaranConTPVId(numAlbaran, this.albaranValue.TPVId);
  }

  
  refrescarAlbaranConTPVId(numAlbaran, TPVId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      // TPVId: this.albaranValue.TPVId,
      TPVId: TPVId,
      NumAlbaran: numAlbaran,
      Pagina: '1',
      NumeroRegistros: '1'
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.setAlbaranValue({ noData: true });
            return;
          }
          // tslint:disable-next-line:max-line-length
          //<<Yaribel 20210217  Añadimos filtro para que no se nos dupliquen las entradas
          if(res.DatosResult && res.DatosResult.ListadoAlbaranInfo[0] && res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems)
          {
            let newAlbaranItems: AlbaranItem[] = [];
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems.forEach((element) => {
              let datos: AlbaranItem = newAlbaranItems.find(item => item.ItemId === element.ItemId && item.TipoProducto === element.TipoProducto && item.PrecioUnidad === element.PrecioUnidad);
              if(datos === null || datos === undefined){
                  const nuevo = {...element, Contactos:[]};
                  nuevo.Contactos.push(element.idContacto);
                  newAlbaranItems.push(nuevo);

              }else{
                datos.Cantidad = (+datos.Cantidad + +element.Cantidad) + '';
                datos.CantidadMaxima = (+datos.CantidadMaxima + +element.CantidadMaxima) + '';
                datos.PrecioTotal = (+datos.PrecioTotal + +element.PrecioTotal) + '';
              }
            });
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems = newAlbaranItems;
          }
          // Yaribel 20210217 >>
          this.setAlbaranValue({
            ...res.DatosResult.ListadoAlbaranInfo[0],
            noData: false,
            NombreTPV: this.turnoService.turnoValue.NombreTPV,
            IdTPV: this.turnoService.turnoValue.TpvId // Yaribel 20210315 Añadimos el pkid del tpv actual
          });
          return this.albaranValue;
        })
      );
  }

  getAlbaranes(idTpv) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: idTpv,
      NumAlbaran: '',
      Pagina: '1',
      NumeroRegistros: '5'
    };
    this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.setAlbaranesValue({ noData: true });
            return;
          }
          //<<Yaribel 20210217  Añadimos filtro para que no se nos dupliquen las entradas

          if(res.DatosResult && res.DatosResult.ListadoAlbaranInfo)
            {
              res.DatosResult.ListadoAlbaranInfo.forEach(Albaran => {
                if (res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems)
          {
            let newAlbaranItems: AlbaranItem[] = [];
            Albaran.AlbaranItems.forEach((element) => {
              let datos: AlbaranItem = newAlbaranItems.find(item => item.ItemId === element.ItemId && item.TipoProducto === element.TipoProducto && item.PrecioUnidad === element.PrecioUnidad);
              if(datos === null || datos === undefined){
                  const nuevo = {...element, Contactos:[]};
                  nuevo.Contactos.push(element.idContacto);
                  newAlbaranItems.push(nuevo);

              }else{
                datos.Cantidad = (+datos.Cantidad + +element.Cantidad) + '';
                datos.CantidadMaxima = (+datos.CantidadMaxima + +element.CantidadMaxima) + '';
                datos.PrecioTotal = (+datos.PrecioTotal + +element.PrecioTotal) + '';
              }
            });
            Albaran.AlbaranItems = newAlbaranItems;
          }
              });
            }
           
          // Yaribel 20210217 >>
          this.setAlbaranesValue({
            albaranes: res.DatosResult.ListadoAlbaranInfo,
            noData: false,
            pagina: body.Pagina,
            NumeroRegistros: body.NumeroRegistros,
            NombreTPV: this.turnoService.turnoValue.NombreTPV
          });
        })
      )
      .subscribe();
  }

  private generarCajasTurnoCantidadAnulacion(): any[] {
    return this.turnoService.cajasValue
      .map((caja, index) => {
        const cajaExistente = this.albaranValue.AlbaranFOPs.find(
          fop => fop.FOP === caja.MedioPago
        );
        if (cajaExistente) {
          return {
            CajasTurnoId: caja.pkid,
            Cantidad: this.conversor(cajaExistente.Importe) * -1
          };
        }
      })
      .filter(el => el != null);
  }

  // TODO: Si se puede hacer multiple habla que cambiarlo como el anterior
  private generarCajasTurnoCantidadDevolucion(): any[] {
    return this.turnoService.cajasValue
      .map((caja, index) => {
        const cajaExistente = this.albaranValue.AlbaranFOPs.find(
          fop => fop.FOP === caja.MedioPago
        );
        if (cajaExistente) {
          return {
            CajasTurnoId: caja.pkid,
            Cantidad: this.devolucionService.carritoDevolucion.precioTotal * -1
          };
        }
      })
      .filter(el => el != null);
  }

  conversor(precio: string) {
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  // TODO
  getAlbaran(idTpv, numAlbaran, Centro, filtro?, pagina?, numeroRegistros?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: idTpv,
      NumAlbaran: String(filtro) === 'NaN' ? '' : String(filtro),
      ClaveCentro : Centro? Centro: "",
      Pagina: pagina || '1',
      NumeroRegistros: numeroRegistros || '1',
      TraerTicketsImpresion: '0'
      // Filtro: filtro
    };
    this.setAlbaranValue(undefined);
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .pipe(debounceTime(400))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.setAlbaranValue({ noData: true });
            return;
          }
          //<<Yaribel 20210217  Añadimos filtro para que no se nos dupliquen las entradas
          if(res.DatosResult && res.DatosResult.ListadoAlbaranInfo[0] && res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems)
          {
            let newAlbaranItems: AlbaranItem[] = [];
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems.forEach((element) => {
              let datos: AlbaranItem = newAlbaranItems.find(item => item.ItemId === element.ItemId && item.TipoProducto === element.TipoProducto && item.PrecioUnidad === element.PrecioUnidad);
              if(datos === null || datos === undefined){
                  const nuevo = {...element, Contactos:[]};
                  nuevo.Contactos.push(element.idContacto);
                  newAlbaranItems.push(nuevo);

              }else{
                datos.Cantidad = (+datos.Cantidad + +element.Cantidad) + '';
                datos.CantidadMaxima = (+datos.CantidadMaxima + +element.CantidadMaxima) + '';
                // datos.PrecioTotal = (+datos.PrecioTotal + +element.PrecioTotal) + '';
                datos.PrecioTotal = (Number.parseFloat(datos.PrecioTotal.replace(',', '.')) + Number.parseFloat(element.PrecioTotal.replace(',', '.'))) + '';
              }
            });
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems = newAlbaranItems;
          }
          // Yaribel 20210217 >>
          this.setAlbaranValue({
            ...res.DatosResult.ListadoAlbaranInfo[0],
            noData: false,
            NombreTPV: this.turnoService.turnoValue.NombreTPV,
            IdTPV: this.turnoService.turnoValue.TpvId // Yaribel 20210315 Añadimos el pkid del tpv actual
          });
          return this.albaranValue;
        })
      );
  }

  generarPeticionDevolucion(
    entradas,
    Identificador?,
    NumAlbaran?,
    pedido?,
    RTS?,
    MCPComercio?,
    MCPTerminal?
  ) {
    // TODO: b.	No se está inicializando correctamente el json de las devoluciones parciales.
    // Las entradas reducidas tienen comisión. En el atributo PrecioPagado tiene que incluir el PVP
    // sin comisión y lo está inicializando incluyéndola. Como la información no está almacenada
    // correctamente luego no la muestra bien. Hay que corregir la generación del json en las devoluciones
    // parciales en el aplicativo de Taquilla.
    this.conf = this.configuration.appConfig.value;
    let comisionTotal = 0;
    entradas.forEach(ticket => {
      comisionTotal +=
        Number.parseFloat(ticket.itemInfo.Comision.replace(/,/, '.')) * -1 || 0;
    });
    this.devolucionService.comision = comisionTotal;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const albaran = this.albaranValue;
    const body = {
      ConexionIacpos: conexionId,
      esVentaCC: albaran.TienePagoCC,
      contadorAlbaranes: {
        TpvId: this.turnoService.turnoValue.TpvId
      },
      contadorOperacionesTarjeta: {
        TpvId: this.turnoService.turnoValue.TpvId
      },
      CodigoCliente: albaran.CodigoClienteId,
      UsuarioID: this.turnoService.turnoValue.UsuarioId,
      TurnoId: this.turnoService.turnoValue.pkid,
      TPVId: this.turnoService.turnoValue.TpvId,
      TotalEntradas: this.generarEntradasDevolucion(entradas).length,
      PrecioBruto: this.generarBruto(entradas),
      DivisaId: albaran.DivisaId,
      TieneDescuento: albaran.TieneDto,
      Descuento: albaran.Descuento,
      GenerarFactura: albaran.GenerarFactura,
      FOP: albaran.FOP,
      FechaHoraEntrada: albaran.FechaHoraEntrada,
      Facturado: albaran.Facturado,
      SerieFactura: albaran.SerieFactura,
      NumFacturaId: albaran.NumFacturaId,
      EntradasConMotivo: albaran.HayEntradasConMotivo,
      SinFechaEntrada: albaran.SinFechaEntrada,
      EsDevolucion: 1,
      TotalDescuento: Number.parseFloat(albaran.TotalDescuento) * -1,
      FormaPagoId: albaran.FPId,
      TotalIVA: Number(
        (
          this.devolucionService.carritoDevolucion.precioTotal * -1 -
          this.devolucionService.carritoDevolucion.precioBase * -1
        ).toFixed(2)
      ),
      NumAlbaran: NumAlbaran ? NumAlbaran : '', // albaran.NumAlbaran,
      Identificador: Identificador ? Identificador : '', // albaran.NumVenta,
      // TotalPagar: this.devolucionService.carritoDevolucion.precioTotal * -1,
      TotalPagar: this.generarTotal(entradas),
      CambioRecibido: this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'  || this.albaranValue.AlbaranFOPs.length < 2 ?
      0.00 : Number.parseFloat(this.devolucionService.cambioValue) +
      Number.parseFloat(this.devolucionService.generarMediosPagoVenta().find(x => x.FOP == 'CA').Importe
        //albaran.CambioRecibido.replace(/,/, '.')
      ),
      CambioDevuelto: this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'  || this.albaranValue.AlbaranFOPs.length < 2 ?
      0.00 : Number.parseFloat(this.devolucionService.cambioValue
        //albaran.CambioDevuelto.replace(/,/, '.')
      ),
      DivisaRecibidaId: albaran.DivisaRecibidaId,
      DivisaDevueltaId: albaran.DivisaDevueltaId,
      NumAlbaranOrigen: albaran.pkId,
      Preventa: albaran.Preventa,
      BonoTexto: albaran.BonoTexto,
      GrupoTipoEntradaId: albaran.AlbaranItems[0].GrupoId,
      OrigenTPV: albaran.TPVId,
      OrigenAlbaran: albaran.NumAlbaran,
      mediosPago:  this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'  || this.albaranValue.AlbaranFOPs.length < 2
      ? this.generarMediosPagoVenta() :
      this.devolucionService.generarMediosPagoVenta()
      .filter(
        fop => fop.Importe < 0 || (fop.Importe == 0 && fop.FOP === 'CA')
      ),
      entradasVendidas: this.generarEntradasDevolucion(entradas),
      recintosVenta: this.generarRecintosVenta(entradas),
      aforosVenta: this.generarAforosDevolucion(entradas),
      motivosgrupos: albaran.AlbaranMotivos || [],
      cajasTurno_cantidad: this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1' || this.albaranValue.AlbaranFOPs.length < 2
      ? this.generarCajasTurnoCantidadDevolucion() :
      this.devolucionService.generarCajasTurnoCantidad(),
      resumenTurno: this.generarResumenTurno(),
      contactoId: albaran.ContactoId,
      IdentificadorAlbaranOrigen: albaran.NumVenta,
      reservaVendida: {
        ClaveReserva: '',
        ReservaId: ''
      },
      OperacionesTarjeta:
        Identificador !== undefined
          ? {
              FechaHora: '',
              Importe: this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1' || this.albaranValue.AlbaranFOPs.length < 2
              ? this.devolucionService.carritoDevolucion.precioTotal :  this.devolucionService.generarMediosPagoVenta()
              .find(fop => fop.FOP === 'CC').Importe * (-1),
              TipoOperacion: '02',
              NumAutorizacion2oBloque: '',
              CodigoAceptacion: 'AC',
              TextoInformativo: 'OPERACION ACEPTADA',
              TextoReservadoControlador: '',
              NumTarjeta: this.albaranValue.PagoCCInfo.NumTarjeta,
              FechaCaducidad: this.albaranValue.PagoCCInfo.FechaCaducidad,
              NumAutorizacion1erBloque: '',
              FechaConciliacion1erBloque: '',
              FechaConciliacion2oBloque: '',
              TieneLector: '1',
              LecturaElectronica: '1',
              PresenciaTitular: '1',
              TarjetaPresente: '1',
              TipoTarjeta: '',
              NumOperacion: pedido,
              IdentEstablecimiento2oBloque: MCPComercio,
              DatosAdicionales: RTS,
              DatosAdicionalesPrivados: MCPTerminal,
              CodigoAccion: '',
              PedidoBase: '',
              CodigoClienteID: '',
              NumReimpresiones: '',
              IntroduccionManual: '',
              DivisaId: ''
            }
          : undefined
    };
    return body;
  }

  generarBruto(entradas) {
    let bruto = 0;
    entradas.forEach(entrada => {
      bruto = bruto + +entrada.itemInfo.PrecioBase.replace(/,/, '.') * +entrada.itemInfo.Cantidad; //Yaribel 20210225 Multiplicamos por cantidad para incluir las ventas con OrdenConjunta
    });
    bruto = bruto * -1;
    const brutoString = bruto.toString();
    return brutoString;
  }

  generarTotal(entradas) {
    let total = 0;
    entradas.forEach(entrada => {
      total = total + +entrada.itemInfo.PrecioUnidad.replace(/,/, '.') * +entrada.itemInfo.Cantidad;//Yaribel 20210225 Multiplicamos por cantidad para incluir las ventas con OrdenConjunta
    });
    total = total * -1;
    const totalString = total.toString();
    return totalString;
  }

  private generarResumenTurno() {
    return {
      TurnoId: this.turnoService.turnoValue.pkid,
      UsuarioId: this.turnoService.turnoValue.UsuarioId,
      TPVId: this.turnoService.turnoValue.TpvId,
      NumEntradas: this.devolucionService.carritoDevolucion.cantidadTotal * -1,
      IngresoAlbaranes:
        this.devolucionService.carritoDevolucion.precioTotal * -1
    };
  }

  private generarEntradasDevolucion(itemsDevolucion): any[] {
    /*       const precioUnidad = Number.parseFloat(ticket.itemInfo.PrecioUnidad.replace(/,/, '.')) * -1;
      const baseImponible = Number.parseFloat(ticket.itemInfo.PrecioBase.replace(/,/, '.')) * -1;
      const comision = Number.parseFloat(ticket.itemInfo.Comision.replace(/,/, '.')) * -1 || 0;
      const ivaComision =
        Number.parseFloat(ticket.itemInfo.IvaComision.replace(/,/, '.')) * -1 || 0;

      this.devolucionService.comision = comision; */
    const entradas = [];
    itemsDevolucion.forEach(item => {
      if(true){
        if (item.itemInfo.TipoProducto === '2') {
          item.itemInfo.PromocionEntradas.forEach((entrada, index) => {
            //<<Yaribel 20210224 Añadimos comprobacion de orden conjunta (añadimos todas las entras de ese item) y QRPromo (añadimos las entradas de esa promo))
            // let totalTicket = item.tickets.length;
            // if((item.tickets[0].ImprimirQRPromo == '1' ||item.tickets[0].ImprimirQRPromo == "True"))
            // {
            //   totalTicket = item.tickets.length * item.itemInfo.PromocionEntradas.length;
            // }
            // if (index < item.tickets.length) { 
              //Yaribel 20210224 >>
              const nuevaEntrada = {
                PrecioPagado:
                  -Math.abs(
                    Number.parseFloat(
                      entrada.PrecioTotal.replace(/,/, '.')
                    )
                  ) /*-
                  (-Math.abs(
                    Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
                  ) || 0)*/,
                DivisaId: this.albaranValue.DivisaDevueltaId,
                NumOrden: entrada.NumeroEntradas,
                NombreTipo: entrada.NombreTipo,
                TipoEntradaId: entrada.TipoEntradaId,
                FechaHoraEntrada: item.itemInfo.FechaHoraEntrada,
                Operacion: 'RF',
                BaseImponible: -Math.abs(
                  Number.parseFloat(entrada.PrecioBase.replace(/,/, '.'))
                ),
                IVA: entrada.IVA,
                Devuelta: '0',
                TipoAbreviado: item.itemInfo.TipoAbreviado || '',
                iNumAlbaran: this.albaranValue.NumAlbaran,
                AbonadoId: '0',
                ClienteAsociadoId: '0',
                OrdenCjta: 0,
                Comision:
                  -Math.abs(
                    Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
                  ) || 0,
                IvaComision: item.itemInfo.IvaComision,
                BonoTexto: item.itemInfo.BonoTexto || '',
                DNIAbonadosExt: item.itemInfo.DNIAbonadosExt || '',
                Identificacion: item.itemInfo.Identificacion || '',
                CodigoTipoControl: 0,
                BarCode: '',
                PromocionId: item.itemInfo.ItemId,
                PromocionNombre: item.itemInfo.NombreTipo,
                PromocionNumOrden: entrada.NumOrdenPromo,
                TarjetaMonederoCodigo: entrada.TarjetaMonederoCodigo || '' // Yaribel 13112020 añadios TarjetaMonederocodigo si lo tenemos
              };
              
              // if(+item.tickets[0].OrdenCjta > 0)
              // {
              //   if (item.tickets[0].ImprimirQRPromo == '1' || item.tickets[0].ImprimirQRPromo == "True"){
              //     entradas.push(nuevaEntrada);
              //   } else {
              //     if(item.tickets[0].ItemId  == entrada.TipoEntradaId)
              //     {
              //       entradas.push(nuevaEntrada);
              //     }
              //   }

              // } else {
              //   if (item.tickets[0].ImprimirQRPromo == '1' || item.tickets[0].ImprimirQRPromo == "True"){
              //     if(item.tickets[0].NumOrdenPromo == entrada.NumOrdenPromo){
              //       entradas.push(nuevaEntrada);
              //   }
              // } 
              // }
              entradas.push(nuevaEntrada);
            // } // Yaribel 20210224
          });
        } else if (item.itemInfo.TipoProducto === '1') {
          //<< Yaribel 20210225 Añadimos un bucle para añadir el total de entradas de las ventas (si es orden Conjunta vienen juntas)
          let cantidadEntradas = item.itemInfo.Cantidad;
          for (let index = 0; index < cantidadEntradas; index++) {
            const nuevaEntrada = {
              PrecioPagado:
                -Math.abs(
                  Number.parseFloat(item.itemInfo.PrecioUnidad.replace(/,/, '.'))
                ) -
                (-Math.abs(
                  Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
                ) || 0),
              DivisaId: this.albaranValue.DivisaDevueltaId,
              NumOrden: +item.numTickets + index, //Generamos el numOrden a partir del primero que viene en la información
              NombreTipo: item.itemInfo.NombreTipo,
              TipoEntradaId: item.tickets[0].ItemId,
              FechaHoraEntrada: item.itemInfo.FechaHoraEntrada,
              Operacion: 'RF',
              BaseImponible: -Math.abs(
                Number.parseFloat(item.itemInfo.PrecioBase.replace(/,/, '.'))
              ),
              IVA: item.itemInfo.IVA,
              Devuelta: '0',
              TipoAbreviado: item.itemInfo.TipoAbreviado || '',
              iNumAlbaran: this.albaranValue.NumAlbaran,
              AbonadoId: '0',
              ClienteAsociadoId: '0',
              OrdenCjta: item.tickets[0].OrdenCjta || 0,
              Comision:
                -Math.abs(
                  Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
                ) || 0,
              IvaComision: item.itemInfo.IvaComision || '',
              
              BonoTexto: item.itemInfo.BonoTexto || '',
              DNIAbonadosExt: item.itemInfo.DNIAbonadosExt || '',
              Identificacion: item.itemInfo.Identificacion || '',
              CodigoTipoControl: item.tickets[0].CodigoTipoControl || '',
              BarCode: item.tickets[0].BarCode || '',
              TarjetaMonederoCodigo: item.itemInfo.TarjetaMonederoCodigo || '', // Yaribel 13112020 añadios TarjetaMonederocodigo si lo tenemos
              EntradasVendidasButacas:
                item.tickets[0].hasOwnProperty('EspectaculosImprimir') &&
                item.tickets[0].EspectaculosImprimir !== null
                  ? this.generarEntradasVendidasButacas(item)
                  : undefined
            };
  
            entradas.push(nuevaEntrada);
            
          }

        }
      } else{
      if (item.itemInfo.TipoProducto === '2') {
        item.itemInfo.PromocionEntradas.forEach((entrada, index) => {
          if (index < item.tickets.length) {
            const nuevaEntrada = {
              PrecioPagado:
                -Math.abs(
                  Number.parseFloat(
                    entrada.PrecioTotal.replace(/,/, '.')
                  )
                ) /*-
                (-Math.abs(
                  Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
                ) || 0)*/,
              DivisaId: this.albaranValue.DivisaDevueltaId,
              NumOrden: entrada.NumeroEntradas,
              NombreTipo: entrada.NombreTipo,
              TipoEntradaId: entrada.TipoEntradaId,
              FechaHoraEntrada: item.itemInfo.FechaHoraEntrada,
              Operacion: 'RF',
              BaseImponible: -Math.abs(
                Number.parseFloat(entrada.PrecioBase.replace(/,/, '.'))
              ),
              IVA: entrada.IVA,
              Devuelta: '0',
              TipoAbreviado: item.itemInfo.TipoAbreviado || '',
              iNumAlbaran: this.albaranValue.NumAlbaran,
              AbonadoId: '0',
              ClienteAsociadoId: '0',
              OrdenCjta: 0,
              Comision:
                -Math.abs(
                  Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
                ) || 0,
              IvaComision: item.itemInfo.IvaComision,
              BonoTexto: item.itemInfo.BonoTexto || '',
              DNIAbonadosExt: item.itemInfo.DNIAbonadosExt || '',
              Identificacion: item.itemInfo.Identificacion || '',
              CodigoTipoControl: 0,
              BarCode: '',
              PromocionId: item.itemInfo.ItemId,
              PromocionNombre: item.itemInfo.NombreTipo,
              PromocionNumOrden: entrada.NumOrdenPromo,
              TarjetaMonederoCodigo: entrada.TarjetaMonederoCodigo || '' // Yaribel 13112020 añadios TarjetaMonederocodigo si lo tenemos
            };
            entradas.push(nuevaEntrada);
          }
        });
      } else if (item.itemInfo.TipoProducto === '1') {
        const nuevaEntrada = {
          PrecioPagado:
            -Math.abs(
              Number.parseFloat(item.itemInfo.PrecioUnidad.replace(/,/, '.'))
            ) -
            (-Math.abs(
              Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
            ) || 0),
          DivisaId: this.albaranValue.DivisaDevueltaId,
          NumOrden: item.numTickets,
          NombreTipo: item.itemInfo.NombreTipo,
          TipoEntradaId: item.tickets[0].ItemId,
          FechaHoraEntrada: item.itemInfo.FechaHoraEntrada,
          Operacion: 'RF',
          BaseImponible: -Math.abs(
            Number.parseFloat(item.itemInfo.PrecioBase.replace(/,/, '.'))
          ),
          IVA: item.itemInfo.IVA,
          Devuelta: '0',
          TipoAbreviado: item.itemInfo.TipoAbreviado || '',
          iNumAlbaran: this.albaranValue.NumAlbaran,
          AbonadoId: '0',
          ClienteAsociadoId: '0',
          OrdenCjta: item.tickets[0].OrdenCjta || 0,
          Comision:
            -Math.abs(
              Number.parseFloat(item.itemInfo.Comision.replace(/,/, '.'))
            ) || 0,
          IvaComision: item.itemInfo.IvaComision || '',
          BonoTexto: item.itemInfo.BonoTexto || '',
          DNIAbonadosExt: item.itemInfo.DNIAbonadosExt || '',
          Identificacion: item.itemInfo.Identificacion || '',
          CodigoTipoControl: item.tickets[0].CodigoTipoControl || '',
          BarCode: item.tickets[0].BarCode || '',
          TarjetaMonederoCodigo: item.itemInfo.TarjetaMonederoCodigo || '', // Yaribel 13112020 añadios TarjetaMonederocodigo si lo tenemos
          EntradasVendidasButacas:
            item.tickets[0].hasOwnProperty('EspectaculosImprimir') &&
            item.tickets[0].EspectaculosImprimir !== null
              ? this.generarEntradasVendidasButacas(item)
              : undefined
        };
        entradas.push(nuevaEntrada);
      }
    }
    });
    return entradas;

    /*     return {
      // tslint:disable-next-line:max-line-length
      PrecioPagado: -Math.abs(Number.parseFloat(item.itemInfo.PrecioUnidad)) - comision,
      DivisaId: this.albaranValue.DivisaDevueltaId,
      NumOrden: ticket.NumTicket,
      NombreTipo: ticket.itemInfo.NombreTipo,
      FechaHoraEntrada: ticket.itemInfo.FechaHoraEntrada,
      Operacion: 'RF',
      BaseImponible: baseImponible,
      IVA: ticket.itemInfo.IVA,
      Devuelta: ticket.Devuelta,
      TipoAbreviado: ticket.itemInfo.TipoAbreviado || '',
      iNumAlbaran: this.albaranValue.NumAlbaran,
      AbonadoId: ticket.itemInfo.AbonadoId || 0,
      ClienteAsociadoId: ticket.itemInfo.AsociadoId || 0,
      OrdenCjta: ticket.OrdenCjta || 0,
      Comision: comision,
      IvaComision: ivaComision,
      BonoTexto: ticket.itemInfo.BonoTexto || '',
      DNIAbonadosExt: ticket.itemInfo.DNIAbonadosExt || '',
      Identificacion: ticket.itemInfo.Identificacion || '',
      CodigoTipoControl: ticket.CodigoTipoControl,
      BarCode: ticket.BarCode,
      TipoEntradaId: ticket.ItemId
    }; */
  }

  generarEntradasVendidasButacas(entrada) {
    return {
      EspectaculoId: entrada.tickets[0].EspectaculosImprimir.EspectaculoId,
      RecintoId: entrada.tickets[0].recintosImprimir[0].RecintoId,
      FechaHora: entrada.tickets[0].recintosImprimir[0].FechaHoraEntrada,
      Fila: entrada.tickets[0].EspectaculosImprimir.NumFila,
      Butaca: entrada.tickets[0].EspectaculosImprimir.NumButaca
    };
  }

  generarRecintosVenta(entradas) {
    let recintos = [];
    if (entradas.length > 0) {
      entradas.forEach(entrada => {
        if ('EspectaculosImprimir' in entrada) {
          recintos.push({
            TipoSesionId: '', // butaca.IdTipoSesion,
            TipoEntradaId: entrada.tickets[0].ItemId,
            recintoId: entrada.tickets[0].recintosImprimir[0].RecintoId,
            fechaEntrada:
              entrada.tickets[0].recintosImprimir[0].FechaHoraEntrada,
            EspectaculoId: entrada.tickets[0].EspectaculosImprimir.EspectaculoId
          });
        }
      });
    }

    recintos = recintos.filter(
      (recinto, index, self) =>
        index ===
        self.findIndex(
          r =>
            r.TipoEntradaId === recinto.TipoEntradaId &&
            r.recintoId === recinto.recintoId &&
            r.fechaEntrada === recinto.fechaEntrada
        )
    );

    return recintos;
  }

  private generarAforosDevolucion(entradas) {
    // Apaño para cadiz, no devolver aforos
    const aforos = {};
    let values = {};
    if (entradas.length > 0) {
      entradas.forEach(entrada => {
        if ('EspectaculosImprimir' in entrada) {
          if (this.albaranValue.AlbaranRecintos) {
            const recinto = this.albaranValue.AlbaranRecintos.find(
              r => r.ItemId === entrada.ItemId
            );
            const cantidad = aforos[recinto.ItemId]
              ? aforos[recinto.ItemId].Cantidad
              : 0;
            aforos[recinto.ItemId] = {
              RecintoId: recinto.RecintoId,
              FechaHora: recinto.FechaEntrada,
              Cantidad: cantidad + 1
            };
          }
        }
      });
      values = Object.values(aforos).map((aforo: any) => {
        aforo.Cantidad = aforo.Cantidad * -1;
        return aforo;
      });
    }
    return values;
  }

  private generarMediosPagoVenta() {
    return this.albaranValue.AlbaranFOPs.map(fop => {
      const importe = this.devolucionService.carritoDevolucion.precioTotal * -1;
      return { FOP: fop.FOP, Importe: importe, TarjetaMonederoCodigo: fop.TarjetaMonederoCodigo };  //Yaribel 11112020 Modificamos para que devuelva el Codigo de la tarjeta monedero
    });
  }

  generarIdentificadores(tpvId, prefijo) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Plataforma: '2',
      Prefijo: prefijo,
      TpvId: tpvId
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/GenerarNumAlbaranIdentificador`,
      body
    );
  }

  generarOperacionTarjeta(albaran) {
    /*
    const body = {
      FechaHora: moment().format('YYYY/MM/DD HH:mm:ss'),
      Importe: this.venta.carritoValue.precioTotal,
      TipoOperacion: '01',
      NumAutorizacion2oBloque: '',
      CodigoAceptacion: 'AC', // TODO control de otros codigos
      TextoInformativo: this.operacionesTarjetaValue.resultado,
      TextoReservadoControlador: this.operacionesTarjetaValue.codigoRespuesta,
      NumTarjeta: this.operacionesTarjetaValue.tarjetaClienteRecibo,
      FechaCaducidad: this.operacionesTarjetaValue.caducidad,
      NumAutorizacion1erBloque: '',
      FechaConciliacion1erBloque: '',
      FechaConciliacion2oBloque: '',
      TieneLector: '1',
      LecturaElectronica: '1',
      PresenciaTitular: '1',
      TarjetaPresente: '1',
      TipoTarjeta: this.operacionesTarjetaValue.etiquetaApp,
      NumOperacion: this.operacionesTarjetaValue.pedido,
      IdentEstablecimiento2oBloque: this.operacionesTarjetaValue.comercio,
      DatosAdicionales: this.operacionesTarjetaValue.identificadorRTS,
      DatosAdicionalesPrivados: this.operacionesTarjetaValue.terminal,
      CodigoAccion: '',
      PedidoBase: this.operacionesTarjetaValue.pedido,
      CodigoClienteID: '',
      NumReimpresiones: '1',
      IntroduccionManual: '0',
      DivisaId: this.turnoService.turnoValue.DivisaId
    };
    return body; */
  }

  createToken() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    }
    const bodyToken = {
      "usuario": environment.API_TOKEN_USER,
      "contrasena": environment.API_TOKEN_PASSWORD
    }
    return this.http
    .post(
      `${this.config.getConfig(
        'API_URL_TAQUILLA'
      )}/Token`,
      bodyToken,
      {
        headers: headers
      })
    .pipe(
      map(async (res: any) => {
        return res.token;
      })
    );
  }

  reimprimirTicketsAlbaran(numAlbaran, idAlbaran) {
    return this.createToken().pipe(
      switchMap(token => {
        console.log('token', token['__zone_symbol__value'])
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idiomaParam = 'idioma=es-ES';
        const tpvIdParam = `tpvId=${this.turnoService.turnoValue.TpvId}`;
        const numAlbaranParam = numAlbaran;        
        const plataformaParam = 'plataforma=2';
        //const codigoCentro = this.configuration.datosInicioValue.PagoContado;
        //const UsuarioId = this.turnoService.turnoValue.UsuarioId;
        //const EsConjunta = 0
        //const EsReimpresion = 1;
        //const TurnoId = this.turnoService.turnoValue.pkid

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${token['__zone_symbol__value']}`
        };
        
        // Retornamos la solicitud HTTP dentro del switchMap
        return this.http.get(
          `${this.config.getConfig('API_URL_TAQUILLA')}/api/Ticket/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${numAlbaranParam}&${plataformaParam}`,
          { headers: headers, responseType: 'json'}
          ).pipe(
            map((response: { datos: string }) => response.datos), 
            tap(pdfBase64 => {
              this.printPDFWithBase64(pdfBase64);
            })
          );
        })
      );
    }
    printPDFWithBase64(base64: string, fileName: string = 'ticket.pdf') {

      const binaryData = atob(base64);
    
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i) & 0xff;
      }
      
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    
      const blobUrl = URL.createObjectURL(blob);
    
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName; 
      link.click();
      URL.revokeObjectURL(blobUrl);
    }

  pad(s, size) {
    while (s.length < (size || 2)) {
      s = '0' + s;
    }
    return s;
  }

  private generarAforosVenta(): any {
    let res = [];
    if (this.albaranValue.AlbaranRecintos) {
      this.albaranValue.AlbaranRecintos.forEach(recinto => {
        if (recinto.EspectaculoId === '0') {
          if (recinto.ControlaAforoVenta === '1' && recinto.Periodo == '0') {
            res.push({
              RecintoId: recinto.RecintoId,
              FechaHora: recinto.FechaEntrada,
              // Cantidad: Number.parseInt(recinto.Cantidad, 10) * -1,
              Cantidad: Number.parseInt(recinto.Cantidad, 10) * -1,
              EsVentaTaquilla: this.albaranValue.Localizador ? '0' : '1'
            });
          }
        }
      });
      res = res.filter(
        (recinto, index, self) =>
          index ===
          self.findIndex(
            r =>
              r.RecintoId === recinto.RecintoId &&
              r.FechaHora === recinto.FechaHora &&
              r.Cantidad === recinto.Cantidad &&
              r.EsVentaTaquilla === recinto.EsVentaTaquilla
          )
      );
      
      res.forEach(element => {
        let cant = 0;
        this.albaranValue.AlbaranRecintos.forEach(e => {
          if(e.RecintoId === element.RecintoId && e.FechaEntrada === element.FechaHora)
          cant += +e.Cantidad;
        });
       element.Cantidad = cant.toString();       
      });
    }
    return res;
  }

  getAlbaranxNum(idTpv, numAlbaran) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: idTpv,
      NumAlbaran: numAlbaran,
      Pagina: '1',
      NumeroRegistros: '1'
    };
    this.http
      .post(`${this.config.getConfig('API_URL')}api/GetDatosAlbaran`, body)
      .pipe(
        map((res: any) => {
          if (res.DatosResult === null) {
            this.setAlbaranValue({ noData: true });
            return;
          }
          //<<Yaribel 20210217  Añadimos filtro para que no se nos dupliquen las entradas
          if(res.DatosResult && res.DatosResult.ListadoAlbaranInfo[0] && res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems)
          {
            let newAlbaranItems: AlbaranItem[] = [];
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems.forEach((element) => {
              let datos: AlbaranItem = newAlbaranItems.find(item => item.ItemId === element.ItemId && item.TipoProducto === element.TipoProducto && item.PrecioUnidad === element.PrecioUnidad);
              if(datos === null || datos === undefined){
                  const nuevo = {...element, Contactos:[]};
                  nuevo.Contactos.push(element.idContacto);
                  newAlbaranItems.push(nuevo);

              }else{
                datos.Cantidad = (+datos.Cantidad + +element.Cantidad) + '';
                datos.CantidadMaxima = (+datos.CantidadMaxima + +element.CantidadMaxima) + '';
                datos.PrecioTotal = (+datos.PrecioTotal + +element.PrecioTotal) + '';
              }
            });
            res.DatosResult.ListadoAlbaranInfo[0].AlbaranItems = newAlbaranItems;
          }
          // Yaribel 20210217 >>
          this.setAlbaranValue({
            ...res.DatosResult.ListadoAlbaranInfo[0],
            noData: false,
            NombreTPV: this.turnoService.turnoValue.NombreTPV,
            IdTPV: this.turnoService.turnoValue.TpvId // Yaribel 20210315 Añadimos el pkid del tpv actual
          });
        })
      )
      .subscribe();
  }
}
