import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Modulo } from './modulo.model';
import { DeviceConfiguration } from './DeviceConfiguration';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from '../toast/error.toast';
import { InfoToast } from '../toast/info.toast';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  dataReady = new BehaviorSubject<boolean>(false);
  appConfig = new BehaviorSubject(undefined);
  internetConfig: any;
  filtrarPorCliente: string;
  estadosButacas = [];
  window = window as any;

  public datosInicioSubject: BehaviorSubject<any>;
  public datosInicio: Observable<any>;

  private divisasSubject: BehaviorSubject<any>;
  public divisas: Observable<any>;

  private datosTPVPathSubject: BehaviorSubject<any>;
  public datosTPVPath: Observable<any>;

  private TPVEnUsoSubject: BehaviorSubject<any>;
  public TPVEnUso$: Observable<any>;

  private listadoTPVsEnUsoSubject: BehaviorSubject<any>;
  public listadoTPVsEnUso$: Observable<any>;

  private tpvPathLocalSubject: BehaviorSubject<any>;
  public tpvPathLocal$: Observable<any>;

  private tpvSeleccionadoSubject: BehaviorSubject<any>;
  public tpvSeleccionado$: Observable<any>;

  private deviceConfigurationSubject: BehaviorSubject<DeviceConfiguration>;
  public deviceConfiguration$: Observable<DeviceConfiguration>;

  private configurationSubject: BehaviorSubject<any>;
  public configuration: Observable<any>;

  private tpvFisicoSubject: BehaviorSubject<any>;
  public tpvFisico$: Observable<any>;

  maxNumImpresiones: any;
  url: string[];
  constructor(
    public translate: TranslateService,
    private config: AppConfiguration,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.datosInicioSubject = new BehaviorSubject(undefined);
    this.datosInicio = this.datosInicioSubject.asObservable();

    this.divisasSubject = new BehaviorSubject(undefined);
    this.divisas = this.divisasSubject.asObservable();

    this.datosTPVPathSubject = new BehaviorSubject(undefined);
    this.datosTPVPath = this.datosTPVPathSubject.asObservable();

    this.deviceConfigurationSubject = new BehaviorSubject(undefined);
    this.deviceConfiguration$ = this.deviceConfigurationSubject.asObservable();

    this.TPVEnUsoSubject = new BehaviorSubject(undefined);
    this.TPVEnUso$ = this.TPVEnUsoSubject.asObservable();

    this.listadoTPVsEnUsoSubject = new BehaviorSubject(undefined);
    this.listadoTPVsEnUso$ = this.listadoTPVsEnUsoSubject.asObservable();

    this.tpvPathLocalSubject = new BehaviorSubject(undefined);
    this.tpvPathLocal$ = this.tpvPathLocalSubject.asObservable();

    this.tpvSeleccionadoSubject = new BehaviorSubject(undefined);
    this.tpvSeleccionado$ = this.tpvSeleccionadoSubject.asObservable();

    this.tpvFisicoSubject = new BehaviorSubject(false);
    this.tpvFisico$ = this.tpvFisicoSubject.asObservable();
    
  }

  public get tpvFisicoValue(){
    return this.tpvFisicoSubject.value;
  }

  setTpvFisico(value){
    this.tpvFisicoSubject.next(value);
  }

  public get deviceConfiguration() {
    return this.deviceConfigurationSubject.value;
  }

  setDeviceConfiguration(conf: DeviceConfiguration) {
    this.deviceConfigurationSubject.next(conf);
  }

  public get configurationValue() {
    return this.configurationSubject.value;
  }

  public get datosInicioValue() {
    return this.datosInicioSubject.value;
  }

  public get divisasValue() {
    return this.divisasSubject.value;
  }

  public get datosTPVPathValue() {
    return this.datosTPVPathSubject.value;
  }

  setDatosInicioValue(configuration: any) {
    this.datosInicioSubject.next(configuration);
  }

  setDivisasValue(divisas: any) {
    this.divisasSubject.next(divisas);
  }

  setDatosTPVPathValue(datosTPVPath: any) {
    this.datosTPVPathSubject.next(datosTPVPath);
  }

  public get listadoTPVsEnUsoValue() {
    return this.listadoTPVsEnUsoSubject.value;
  }

  public get TPVEnUsoValue() {
    return this.TPVEnUsoSubject.value;
  }

  setListadoTPVsEnUsoValue(listado) {
    this.listadoTPVsEnUsoSubject.next(listado);
  }

  setTPVEnUsoValue(enUso) {
    this.TPVEnUsoSubject.next(enUso);
  }
  public get tpvPathLocalValue() {
    return this.tpvPathLocalSubject.value;
  }
  setTpvPathLocal(VMPath) {
    this.tpvPathLocalSubject.next(VMPath);
  }

  public get tpvSeleccionadoValue(){
    return this.tpvSeleccionadoSubject.value;
  }

  setTPVSeleccionadoValue(valor){
    this.tpvSeleccionadoSubject.next(valor);
  }


  getTPVPathLocal() {
    if (!this.tpvPathLocalValue) { 
      const config = this.getTPVPathOrigenValue();    
      this.setTpvPathLocal(config);
    }
  }

  getTPVPathOrigenValue(loginUrl: string = null){
   let config = this.config.getConfig('TPV_PATH');;
    if (
      this.window.external &&
      typeof this.window.external.obtenerEquipo === 'function' && loginUrl === null
    ) 
    {
      config = this.window.external.obtenerEquipo();
      this.setTpvFisico(true);
    } else if (loginUrl != null) {
      config = loginUrl;
    } 
    return config;
  }

  getInternetConfig() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
    };

    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/TraerDatosInicioInternet`,
        body
      )
      .pipe(
        map((res: any) => {
          this.internetConfig = res;
        })
      );
  }

  getAppConfig(id) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));

    const body = {
      ConexionIacpos: conexionId,
      CodigoApp: this.config.getConfig('CODIGO_APLICACION'),
      CodigoModulo: '',
      Id: id ? id : this.config.getConfig('ID_APLICACION'),
    };
    this.getEstadosButacas();

    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Configuracion/GetAppConfig`,
        body
      )
      .pipe(
        map((res: any) => {
          this.appConfig.next(res);
          this.filtrarPorCliente = res.DatosResult.AppConfigJsonConfig && res.DatosResult.AppConfigJsonConfig.ClienteFiltrarProductosPorCliente?
            res.DatosResult.AppConfigJsonConfig.ClienteFiltrarProductosPorCliente : null;
          this.maxNumImpresiones = res.DatosResult.AppConfigJsonConfig && res.DatosResult.AppConfigJsonVentaGestionar.ReimprimiticketMax?
            res.DatosResult.AppConfigJsonVentaGestionar.ReimprimiticketMax: null;
          this.setDeviceConfiguration(res.DatosResult);
          return res;
        })
      );
  }

  getEstadosButacas() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: 'es-ES',
      Aplicacion: '2',
    };
    this.http
      .post(`${this.config.getConfig('API_URL')}api/EstadosButacas`, body)
      .subscribe((data: any) => {
        this.estadosButacas = data.DatosResult.Estados;
      });
  }

  getModulos() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      AplicacionCod: this.config.getConfig('CODIGO_APLICACION'),
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Customan/ObtenerModulos`,
        body
      )
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }
          // TODO revisar que hacer con el modulo.Orden
          return res.DatosResult.ListaModulos.filter(
            (modulo: Modulo) => modulo.Estado == '1'
          )
            .map((modulo: Modulo) => {
              const m = {
                ...modulo,
                url: modulo.Ventana,
                disabled: false,
                nombre: modulo.Nombre,
                Orden: +modulo.Orden,
              };
              return m;
            })
            .sort((a, b) => a.Orden - b.Orden);
        })
      );
  }

  getTPVEnUso(config: string, path?: string){
    this.url = this.router.url.split('/');
    path = this.url[2];
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    if (path) {
      return this.http.post(`${this.config.getConfig('API_URL')}api/GetTPVEnUso`,
      {
        ConexionIacpos: conexionId,
        VMPath: path
      }
    ).pipe(map((TPVPath: any) => {
      if(TPVPath.DatosResult){
        if(TPVPath.DatosResult.TPVsDisponibles){
          this.setListadoTPVsEnUsoValue(TPVPath.DatosResult.TPVsDisponibles);
        }

        this.setTPVEnUsoValue(TPVPath.DatosResult.Estado);
        if(TPVPath.DatosResult.Estado == '1')
        {
          this.toastr.info(this.translate.instant('TPV_EN_USO'),'Info', {
            toastComponent: InfoToast,
            timeOut: 10000
          });

        }
        return TPVPath.DatosResult;
      }
    }))
} else {
  return this.http.post(`${this.config.getConfig('API_URL')}api/GetTPVEnUso`,
  {
    ConexionIacpos: conexionId,
    VMPath: config
  }
).pipe(map((TPVPath: any) => {
  if(TPVPath.DatosResult){
    if(TPVPath.DatosResult.TPVsDisponibles){
      this.setListadoTPVsEnUsoValue(TPVPath.DatosResult.TPVsDisponibles);
    }

    this.setTPVEnUsoValue(TPVPath.DatosResult.Estado);
    if(TPVPath.DatosResult.Estado == '1')
    {
      this.toastr.info(this.translate.instant('TPV_EN_USO'),'Info', {
        toastComponent: InfoToast,
        timeOut: 10000
      });

    }
    return TPVPath.DatosResult;
  }
}))
}

}

}

    //const config = this.config.getConfig('TPV_PATH');
