import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, Renderer2, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { map, mergeMap, take, takeWhile } from 'rxjs/operators';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { VentaService } from 'src/app/modules/venta';
import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { DesglosarCambioService } from '../../services/desglosar-cambio.service';
import { TranslateService } from '../../../../shared/services/translate.service';


@Component({
  selector: 'ticketing-desglosar-cambio',
  templateUrl: './desglosar-cambio.component.html',
  styleUrls: ['./desglosar-cambio.component.scss']
})

export class DesglosarCambioComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() id: string;
  loaded = false;
  controlData;


  datosInforme;
  usuarios;
  productosVenta;


  monedaSeleccionada;
  monedasDisponibles;
  CantidadMonedas;
  total;
  cambioExistente;
  diferencia = 0;
  cambio = {
    cambioInicial: 0,
    cambioIntroducido: 0,
    cambioRetirado: 0,
    ventasEfectivo: undefined,
    cambioResultante: 0
  };
  private alive = true;

  private element: any;
  window = window as any;
  desgloseForm: FormGroup;
  billetesMonedasForm: FormGroup;
  myFormSub: Subscription;
  desgloseSub: Subscription;
  cerrarTurnoSub: Subscription;
  consultarEfectivoCajaSub: Subscription;
  billetesMonedasFormSub: Subscription;
  elementSelected: ElementRef;
  actualFocus: any;
  focus = true;

  constructor(
    private formBuilder: FormBuilder, private formControlService: FormControlService,
    private numpadService: NumpadService, private turnoService: TurnoService,
    private configuration: ConfigurationService, private desgloseService: DesglosarCambioService,
    private modalService: ModalService,
    private el: ElementRef,
    private confirmationService: ConfirmationService,
    private toast: ToastrService,
    private printService: PrintService,
    private userService: UserManagerService,
    private ventaService: VentaService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) {

    this.element = el.nativeElement;
    this.monedasDisponibles = this.desgloseService.billetesMonedasValue;
    this.controlData = {};

  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
    //this.monedasDisponibles = this.desgloseService.billetesMonedasValue;
    // this.billetesMonedasForm = this.formBuilder.group({
    //   billetesMonedas: this.formBuilder.array([
    // 	])
    // });
    //this.createDynamicForm(); 
    this.desgloseForm = this.formBuilder.group({
      diferencia: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      ventasEfectivo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      cambioExistente: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      comentario: [
        '',
        Validators.compose([Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9á-ý\\u00f1\\u00d1\\s]*$')])
      ]
    });
  }

  ngOnDestroy() {
    // this.alive = false;
    // this.myFormSub.unsubscribe();
    // this.desgloseSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }


  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
    this.alive = true;
    this.monedasDisponibles = JSON.parse(JSON.stringify(this.configuration.divisasValue[0].ListadoMonedasBilletes));
    this.desgloseService.setBilletesMonedas(this.monedasDisponibles);
    this.desgloseSub = this.desgloseService.billetesMonedas$.subscribe(listado => {
      this.monedasDisponibles = listado;

      this.calcularCambio();

      this.createDynamicForm();
    });
    //this.createDynamicForm();  
    if (this.turnoService.cajaValue && this.turnoService.cajaValue.CajaId) {
      this.consultarEfectivoCajaSub = this.turnoService
        .consultarEfectivoCaja()
        .pipe(takeWhile(() => this.alive))
        .subscribe((res: any) => {
          this.cambio.ventasEfectivo = Number.parseFloat((res.DatosResult.ImporteTotalCaja || '0').replace('.', ','));
          this.turnoService.setCambioValue(res.DatosResult);
          this.desgloseForm.controls['cambioExistente'].patchValue((this.cambio.ventasEfectivo || 0).toString());

        });
    }
    this.onChanges();
  }

  parse(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  onChanges(): void {
    this.myFormSub = this.desgloseForm.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      if (val) {
        this.total = Number.parseFloat((val.ventasEfectivo || '0').replace(',', '.')) || 0;
        this.cambioExistente = Number.parseFloat((val.cambioExistente || '0').replace(',', '.')) || 0;
        this.diferencia = this.desgloseService.ventanaOrigenValue == 'introducir' ? this.total + this.cambioExistente : this.cambioExistente - this.total;
        //this.desgloseForm.controls['diferencia'].patchValue((this.diferencia || 0).toString());
      }
    });

    this.billetesMonedasFormSub = this.billetesMonedasForm.valueChanges.subscribe(val => {
      if (val) {

      }
    });
  }

  getCantidadPorMoneda(moneda): number {
    let cantidad = 0;
    if (this.monedasDisponibles.find(x => x.Nombre === moneda.Nombre).cantidad) {
      cantidad = this.monedasDisponibles.find(x => x.Nombre === moneda.Nombre).cantidad;
    } else {
      cantidad = 0;
    }
    return cantidad;

  }

  focusValue(event: any) {
    if (this.formControlService.currentFormGroupValue && this.formControlService.currentFormGroupValue.tipo === 'DESGLOSE') {
      if (this.formControlService.currentFormGroupValue.elemento.value == '') {
        this.formControlService.currentFormGroupValue.elemento.patchValue('0');
      }
    }
    this.formControlService.setCurrentFormGroup(
      this.desgloseForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  selectMoneda(event, monedaObj, index) {
    event.stopPropagation();
    let moneda = this.monedasDisponibles.find(x => x.pkid == monedaObj.value.pkid);
    this.monedaSeleccionada = monedaObj;
    this.actualFocus = index;
    this.desgloseService.index = index;
    this.desgloseService.billete = moneda;
    let children;
    if (event.target.name == 'quantity') {
      return;
    } else if (event.target.className.includes('desglose-cell')) {
      children = event.target.children;
    } else {
      children = event.target.parentElement.children;
    }
    Object.keys(children).forEach((key) => {
      if (children[key].tagName === 'INPUT') {
        children[key].focus();
      }
    });
  }

  focusValueMonedas(event: any, monedaObj: any, index): void {

    let moneda = this.monedasDisponibles.find(x => x.pkid == monedaObj.value.pkid);
    this.monedaSeleccionada = monedaObj;
    // if(this.formControlService.currentFormGroupValue && this.formControlService.currentFormGroupValue.tipo === 'DESGLOSE'){
    //   if(this.formControlService.currentFormGroupValue.elemento.value == '')
    //   this.formControlService.currentFormGroupValue.elemento.patchValue('0');
    // }
    if (event.srcElement.value == '0') {
      event.srcElement.value = '';
    }
    this.desgloseService.selected = event.srcElement;
    this.desgloseService.billete = moneda;
    this.formControlService.setCurrentFormGroup(
      this.billetesMonedasForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  unfocusValue(event: any): void {

  }

  actualizarMoneda(event: any, monedaObj, indexObj) {

    let moneda = this.monedasDisponibles.find(x => x.pkid == monedaObj.value.pkid);
    const index = this.monedasDisponibles.findIndex(x => x.pkid == monedaObj.value.pkid);
    if ((event.srcElement.value).length > 4) {
      event.srcElement.value = (event.srcElement.value).substring(0, 4);
      //event.srcElement.patchValue((event.srcElement.value).substring(0,4));
      // 
      // this.billetesMonedasForm.controls[event.srcElement.getAttribute('formcontrolname')].patchValue((event.srcElement.value).substring(0,4));
    }
    let listaDesgloseMonedas = this.desgloseService.billetesMonedasValue;
    const oldMoneda = listaDesgloseMonedas.find(x => x.pkid == moneda.pkid);
    // const index = listaDesgloseMonedas.findIndex(x => x == oldMoneda);
    this.actualFocus = indexObj;

    if (event.srcElement.value != oldMoneda.cantidad) {
      if (!this.desgloseService.validarNumero(event.srcElement.value)) {
        var regex = /(\d+)/g;
        const numeros = (event.srcElement.value).match(regex);
        event.srcElement.value = numeros && numeros != undefined && numeros.length > 0 ? numeros.join('') : '0';
        // event.srcElement.patchValue(numeros && numeros != undefined && numeros.length > 0? numeros.join(""): '0');
      }
      if (event.srcElement.value == '') {
        // event.srcElement.patchValue('0');
        event.srcElement.value = '0';
      }

      listaDesgloseMonedas[index] = { ...listaDesgloseMonedas[index], cantidad: Number.parseInt(event.srcElement.value) };
      this.desgloseService.setBilletesMonedas(listaDesgloseMonedas);
      //event.srcElement.focus();

    }

  }

  async inputDeTeclado(event: any, moneda) {
    event.preventDefault();
    const charCode = event.keyCode;

    this.desgloseService.selected = event.srcElement;
    this.desgloseService.billete = moneda;
    this.formControlService.setCurrentFormGroup(
      this.billetesMonedasForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );

    if (this.isDeleteButtonPressed(charCode)) {
      this.formControlService.deleteChar();
      // await event.srcElement.focus();
      return;
    }

    if (this.isEnterButton(charCode)) {
      this.formControlService.unfocus();
      await event.srcElement.blur();
      return;
    }

    if (this.isNumberKey(charCode)) {
      this.formControlService.inputChar(event.key);
      // await event.srcElement.focus();
      return;
    }
  }

  isNumberKey(charCode: number) {
    if (charCode > 31 && ((charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105))) {
      return false;
    }
    return true;
  }

  isDeleteButtonPressed(charCode: number) {
    return charCode === 8 ? true : false;
  }

  isEnterButton(charCode: number) {
    return charCode === 13 ? true : false;
  }

  private uniq_fast(a) {
    const seen = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  tienePermiso(accion: string) {
    // const conf = {
    //   toast: false,
    //   elevarUsuario: false
    // };
    //  return this.permisosService.puedeAccionar(this.user.currentUserValue, accion, conf);
    return true;
  }

  calcularCambio() {
    let importe = this.obtenerCambio();

    this.desgloseForm.controls['ventasEfectivo'].patchValue((importe || 0).toString());
  }

  obtenerCambio() {
    let importe = 0;
    if (this.monedasDisponibles) {
      this.monedasDisponibles.forEach(element => {
        if (element.cantidad) {
          importe = importe + (Number.parseFloat(element.Valor.replace(',', '.')) * element.cantidad);
        }
      });
    }
    return importe.toFixed(2);
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
    element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  GuardarDesglose() {
    this.monedasDisponibles.forEach(element => {
      if (element.cantidad && element.cantidad > 0) {
        this.desgloseService.addBilletesMonedas(element);
      }
    });
    this.desgloseService.setEsperarDesglose(false);
    this.regresarDesglose();

  }

  close(): void {
    this.alive = false;
    if (this.myFormSub) {
      this.myFormSub.unsubscribe();
    }
    if (this.desgloseSub) {
      this.desgloseSub.unsubscribe();
    }
    if (this.consultarEfectivoCajaSub) {
      this.consultarEfectivoCajaSub.unsubscribe();
    }
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    if (this.billetesMonedasFormSub) {
      this.billetesMonedasFormSub.unsubscribe();
    }
    //this.billetesMonedasForm.reset();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  regresarDesglose() {
    let desglose = this.desgloseService.listadoBilletesMonedasValue || [];
    let comentarios = this.desgloseForm.controls['comentario'].value || '';
    const efectivo = this.desgloseService.getTotalDesglose();
    switch (this.desgloseService.ventanaOrigenValue) {
      case 'introducir':
        this.turnoService.ingresarEfectivo(efectivo, comentarios, desglose).subscribe
        ((res: any) => {
            this.desgloseService.inicializarDesglose();
            if (!res.DatosResult) {
              this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                toastComponent: ErrorToast,
                timeOut: 10000
              });
              return;
            }
            this.toast.info(
              this.translate.instant('OPERACION_REALIZADA_CORRECTAMENTE'),
              this.translate.instant('INGRESO'), {
              toastComponent: InfoToast,
              timeOut: 10000
            });
          }
        );
        break;
      case 'retirar':
        this.turnoService.retirarEfectivo(efectivo, comentarios, desglose).subscribe((res: any) => {
          this.desgloseService.inicializarDesglose();
          if (!res.DatosResult) {
            this.toast.error(res.Mensajes[0].DescripcionMensaje, this.translate.instant('ERROR'), {
              toastComponent: ErrorToast,
              timeOut: 10000
            });
            return;
          }
          this.toast.info(this.translate.instant('OPERACION_REALIZADA_CORRECTAMENTE'), this.translate.instant('RETIRO'), {
            toastComponent: InfoToast,
            timeOut: 10000
          });
        });
        break;
      case 'cerrarTurno':
        this.cerrarTurno();
        break;
      default:
        break;
    }
    this.close(); // Importante cerrar el modal del desglose para que no se quede en primer plano
  }

  cerrarTurno() {
    this.modalService.open('confirmarCierreTurno');
    const comentarios = this.desgloseForm.controls['comentario'].value || '';
    this.cerrarTurnoSub = this.confirmationService.confirmado
      .subscribe(confirmado => {

        if (confirmado) {
          this.turnoService
            .cerrarCaja(this.desgloseService.getTotalDesglose(), comentarios, (this.desgloseService.listadoBilletesMonedasValue || []))
            .pipe(
              mergeMap(res => {
                return this.turnoService
                  .generarInformeCierre(this.turnoService.cajaValue.CajaId)
                  .pipe(
                    map((datosInforme: any) => {
                      return { ...res, datosInforme: datosInforme.DatosResult };
                    })
                  );
              })
            )
            .pipe(take(1))
            .subscribe(async (res: any) => {
              if (!res.DatosResult) {
                this.closeModals();
                this.toast.error(res.Mensajes[0].DescripcionMensaje, this.translate.instant('ERROR'), {
                  toastComponent: ErrorToast,
                  timeOut: 5000
                });
                this.cerrarTurnoSub.unsubscribe();
                return;
              }
              this.turnoService.getDatosTPVPath().subscribe();
              this.datosInforme = res.datosInforme;
              this.usuarios = res.datosInforme ? res.datosInforme.ListadoCajaUsuarios : null;
              this.productosVenta = res.datosInforme ? res.datosInforme.ListadoCajaProductos : null;
              this.turnoService.setTurnoValue(undefined);
              this.closeModals();
              this.reset();
              this.toast.info('', this.translate.instant('CAJA_CERRADA'), {
                toastComponent: InfoToast,
                timeOut: 10000
              });
              this.configuration.dataReady.next(true);
              //Metemos un bucle segun configuracion 
              let cantidadImpresiones = 0;
              if (this.configuration.deviceConfiguration.AppConfigJsonTurno && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre != null && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre.trim() != '') {
                cantidadImpresiones = this.configuration.deviceConfiguration.AppConfigJsonTurno && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre ? Number.parseInt(this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre) : 0;


              }
              //Cargamos de configuracion
              setTimeout(() => {
                if (cantidadImpresiones > 0) {
                  for (let i = 0; i < cantidadImpresiones; i++) {
                    this.printService.printCashClosureReport(this.datosInforme);
                  }
                }
              }, 5000);
              if (
                this.window.external &&
                typeof this.window.external.salir === 'function'
              ) {
                //Prueba con delay
                await this.delay(10000);
                this.window.external.salir();
              }
              this.desgloseService.inicializarDesglose();
              this.cerrarTurnoSub.unsubscribe();
            });
        }
      });
  }

  closeModals() {
    this.modalService.close('confirmarCierreTurno');
    this.modalService.close('desglosarCambio');
    this.modalService.close('gestionarTurno');
  }

  reset() {

    this.userService.logout();
    this.ventaService.clearCarrito();
    this.turnoService.setTurnoValue(undefined);
    this.confirmationService.setConfirmado(false);
    this.userService.setCurrentUser(null);
    this.turnoService.setCambioValue(null);
    this.turnoService.setTurnoValue(null);
  }

  obtenerTitulo() {
    let titulo = '';
    switch (this.desgloseService.ventanaOrigenValue) {
      case 'login':
        titulo = 'CAMBIO_INICIAL';
        break;
      case 'introducir':
        titulo = 'CAMBIO_INTRODUCIR';
        break;
      case 'retirar':
        titulo = 'CAMBIO_RETIRAR';
        break;
      default:

        break;

    }
    return titulo;
  }

  obtenerDiferencia() {
    let diferencia = 0;
    let total;
    let cambioExistente;
    // this.total = Number.parseFloat((val.ventasEfectivoInput || '0').replace(',', '.')) || 0;
    // this.cambioExistente = Number.parseFloat((val.arqueoEfectivo || '0').replace(',', '.')) || 0;
    if (this.desgloseService.ventanaOrigenValue == 'introducir') {
      diferencia = this.total + this.cambioExistente;
    } else {
      diferencia = this.total - this.cambioExistente;
    }
    return this.diferencia;
  }

  devolverFoco(event: any, moneda) {

  }


  createDynamicForm(): void {
    this.loaded = false;
    const controls = {};
    //const listado = JSON.parse(JSON.stringify(this.monedasDisponibles));

    this.monedasDisponibles.forEach((item) => {
      this.controlData[item.Valor] = item;
      controls[item.Valor] = new FormControl(0);
    });
    this.billetesMonedasForm = new FormGroup(controls);
    this.loaded = true;

    // // setup the form
    //  const formGroup = {};

    // if (this.billetesMonedasForm) {
    //   Object.keys(this.billetesMonedasForm.controls).forEach(key => {
    //     this.billetesMonedasForm.controls[key].setErrors(null);
    //     this.billetesMonedasForm.controls[key].reset();
    //     this.billetesMonedasForm.controls[key].clearValidators();
    //     this.billetesMonedasForm.updateValueAndValidity();
    //   });
    // }

    // // for (const prop of Object.keys(this.monedasDisponibles)) {
    // //   formGroup[this.monedasDisponibles[prop].pkid] = new FormControl(
    // //     this.monedasDisponibles[prop].cantidad || '0'
    // //   );
    // // }


    // // for (const prop of this.monedasDisponibles) {
    // //   formGroup[prop.nombre] = new FormControl(
    // //     prop.cantidad || '0'
    // //   );
    // // }


    // // this.monedasDisponibles.forEach(moneda => {
    // //   let fg = this.formBuilder.group(moneda);

    // //   this.billetesMonedas.push(moneda);
    // // });


    // // this.monedasDisponibles.forEach(moneda => {
    // //   this.billetesMonedasForm.addControl(moneda.pkid, new FormControl(moneda.cantidad || '0'));
    // // });

    // // this.billetesMonedasForm = new FormGroup(formGroup);


    // // const listado = JSON.parse(JSON.stringify(this.monedasDisponibles))

    // const listado = this.monedasDisponibles.map(data => {
    //   return  {Nombre: data.Nombre}
    // });
    // listado.forEach(moneda => {
    //   let fg = this.formBuilder.group(moneda);

    //   this.billetesMonedas.push(moneda);
    // });

  }

  get billetesMonedas(): FormArray {
    return this.billetesMonedasForm.get('billetesMonedas') as FormArray;
  }

  isMonedaseleccionada(moneda) {
    return moneda === this.monedaSeleccionada;
  }

  ventanaOrigenValue() {
    return this.desgloseService.ventanaOrigenValue
  }

}
