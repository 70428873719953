import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrintService } from '../../services/print.service';
import { Subscription, forkJoin } from 'rxjs';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { mergeMap, take } from 'rxjs/operators';
import { FacturaService } from '../../services/factura.service';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'ticketing-impresion-modal',
  templateUrl: './impresion-modal.component.html',
  styleUrls: ['./impresion-modal.component.scss']
})
export class ImpresionModalComponent implements OnInit {
  @Input() id: string;
  private element: any;

  formSubscription: Subscription;
  opcionesImpresionForm: FormGroup;
  constructor(
    private modalService: ModalService,
    el: ElementRef,
    private router: Router,
    private fb: FormBuilder,
    private printService: PrintService,
    private albaranService: AlbaranService,
    private facturaService: FacturaService,
    private config: AppConfiguration,
    private pagoService: PagoService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.opcionesImpresionForm = this.fb.group({
      imprimirTodo: false,
      entradas: true,
      // ticketCompra: false,
      albaran: false,
      factura: [
        {
          value:
            this.albaranService.albaranValue &&
            this.albaranService.albaranValue.Facturado === '0'
              ? true
              : false,
          disabled:
            this.albaranService.albaranValue &&
            this.albaranService.albaranValue.Facturado === '0'
              ? true
              : false
        }
      ],
      // localizador: false,
      etiquetas: false
    });

    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.formSubscription = this.opcionesImpresionForm.controls[
      'imprimirTodo'
    ].valueChanges.subscribe(val => {
      if (val) {
        this.marcarTodo();
      } else {
        this.desmarcarTodo();
      }
    });

    if (this.albaranService.albaranValue) {
      if (this.albaranService.albaranValue.Facturado === '0') {
        this.opcionesImpresionForm.controls['factura'].disable();
        this.opcionesImpresionForm.controls['factura'].patchValue(false);
      }

      if (this.albaranService.albaranValue.TieneDevoluciones === '2') {
        this.opcionesImpresionForm.controls['entradas'].disable();
        this.opcionesImpresionForm.controls['entradas'].patchValue(false);
        this.opcionesImpresionForm.controls['etiquetas'].disable();
        this.opcionesImpresionForm.controls['etiquetas'].patchValue(false);
      } else {
        this.opcionesImpresionForm.controls['entradas'].enable();
        this.opcionesImpresionForm.controls['etiquetas'].enable();
      }

      if (this.albaranService.albaranValue.TipoOperacion === '01') {
        this.opcionesImpresionForm.controls['entradas'].disable();
        this.opcionesImpresionForm.controls['entradas'].patchValue(false);
        this.opcionesImpresionForm.controls['etiquetas'].disable();
        this.opcionesImpresionForm.controls['etiquetas'].patchValue(false);
      }
    } else {
      this.opcionesImpresionForm.controls['factura'].disable();
      this.opcionesImpresionForm.controls['factura'].patchValue(false);
      this.opcionesImpresionForm.controls['entradas'].enable();
      this.opcionesImpresionForm.controls['etiquetas'].enable();
    }
  }

  close(): void {
    this.printService.setOpcionesDeImpresion(this.opcionesImpresionForm.value);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    if(this.formSubscription){this.formSubscription.unsubscribe();}
  }

  aceptarOpcionesEImprimir() {
    this.printService.setOpcionesDeImpresion(this.opcionesImpresionForm.value);
    if (this.printService.opcionesDeImpresionValue.factura) {
      this.facturaService
        .recuperarFacturaRelacion(this.albaranService.albaranValue)
        .pipe(
          mergeMap((res: any) => {
            if (res) {
              const facturas = res.DatosResult.Facturas.map(factura =>
                this.facturaService.pdfFactura(factura)
              );
              return forkJoin(facturas);
            }
          })
        )
        .subscribe((facturas: any) => {
          if (facturas) {
            for (let i = 0; i < facturas.length; i++) {
              this.printService.printFacturaPDF(facturas[i].DatosResult); // printPurchaseInvoice
            }
          }
        });
    }

    if (this.printService.opcionesDeImpresionValue.albaran) {
      // 
      const albaranValue = this.albaranService.albaranValue;
      const NombreTPV = this.albaranService.albaranValue.NombreTPV;
      const IdTPV = this.albaranService.albaranValue.IdTPV;
      this.pagoService.datosCabecera().pipe(take(1)).subscribe(data => {
    //  if (albaranValue.Localizador !== '') {
    //     this.printService.printOnlineSourceAlbaran(
    //       { datos: albaranValue },
    //       undefined,
    //       undefined,
    //       data
    //     );
    //   } else 
      if (
        albaranValue.TipoOperacion === '01' &&
        albaranValue.EsDevolucion === 'False'
      ) {
        this.printService.printRefundAlbaran({
          datos: albaranValue,
          NombreTPV: NombreTPV,
          IdTPV: IdTPV
        }, 
        data);

      } else if (albaranValue.EsDevolucion === 'True') {
        this.printService.printPartialRefund({
          datos:albaranValue
        }, data);
      } else {
        // 
          this.printService.printPurchaseAlbaran(
            { datos: albaranValue },
            data
          );
        }
    });
      if (this.config.getConfig('COMPRAINICIO')) {
        this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
      } else {
        this.router.navigate(['']);
      }
    }

    // if (this.printService.opcionesDeImpresionValue.albaran) {
    //   
    //   if (this.albaranService.albaranValue.Localizador !== '') {
    //     this.printService.printOnlineSourceAlbaran(
    //       this.albaranService.albaranValue,
    //       undefined,
    //       undefined
    //     );
    //   } else if (
    //     this.albaranService.albaranValue.TipoOperacion === '01' &&
    //     this.albaranService.albaranValue.EsDevolucion === 'False'
    //   ) {
    //     this.printService.printRefundAlbaran({
    //       datos: this.albaranService.albaranValue,
    //       NombreTPV: this.albaranService.albaranValue.NombreTPV
    //     });
    //   } else if (this.albaranService.albaranValue.EsDevolucion === 'True') {
    //     this.printService.printPartialRefund({
    //       datos: this.albaranService.albaranValue
    //     });
    //   } else {
    //     
    //     const albaranValue = this.albaranService.albaranValue;
    //     this.pagoService.datosCabecera().subscribe(data => {
    //       this.printService.printPurchaseAlbaran(
    //         { datos: albaranValue },
    //         data
    //       );
    //     });
    //   }
    //   if (this.config.getConfig('COMPRAINICIO')) {
    //     this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
    //   } else {
    //     this.router.navigate(['']);
    //   }
    // }
    if (this.printService.opcionesDeImpresionValue.etiquetas) {
      //this.modalService.open('etiquetas');
      if (this.config.getConfig('COMPRAINICIO')) {
        this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
      } else {
        this.router.navigate(['']);
      }
    }

    if (this.printService.opcionesDeImpresionValue.entradas) {
      this.router.navigate(['gestionar-venta', 'imprimir']);
    }
    this.close();
  }

  marcarTodo() {
    if (!this.printService.ventaAnulada) {
      if (this.albaranService.albaranValue.TipoOperacion !== '01') {
        this.opcionesImpresionForm.controls['entradas'].patchValue(true);
      }
      this.opcionesImpresionForm.controls['etiquetas'].patchValue(false);
    }

    if (this.albaranService.albaranValue.TipoOperacion === '01') {
      this.opcionesImpresionForm.controls['etiquetas'].patchValue(false);
    }

    if (this.albaranService.albaranValue.TieneDevoluciones === '2') {
      this.opcionesImpresionForm.controls['entradas'].patchValue(false);
      this.opcionesImpresionForm.controls['etiquetas'].patchValue(false);
    }

    // this.opcionesImpresionForm.controls['ticketCompra'].patchValue(true);
    this.opcionesImpresionForm.controls['albaran'].patchValue(true);
    if (
      this.albaranService.albaranValue &&
      this.albaranService.albaranValue.Facturado === '1'
    ) {
      this.opcionesImpresionForm.controls['factura'].patchValue(true);
    }
    // this.opcionesImpresionForm.controls['localizador'].patchValue(true);
  }

  desmarcarTodo() {
    this.opcionesImpresionForm.controls['entradas'].patchValue(false);
    // this.opcionesImpresionForm.controls['ticketCompra'].patchValue(false);
    this.opcionesImpresionForm.controls['albaran'].patchValue(false);
    if (
      this.albaranService.albaranValue &&
      this.albaranService.albaranValue.Facturado === '1'
    ) {
      this.opcionesImpresionForm.controls['factura'].patchValue(false);
    }
    // this.opcionesImpresionForm.controls['localizador'].patchValue(false);
    this.opcionesImpresionForm.controls['etiquetas'].patchValue(false);
  }
}
