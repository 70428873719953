import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { ConnectionType } from '../../connection/connectionType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { CashClosureReportOptions } from '../printOptions';
import { PrintableType } from '../printableType.enum';
import { Printer } from '../printer.interface';
import { TranslateService } from '../../../../shared/services/translate.service';

export class CashClosureReport implements Printer {
  private window = window as any;
  private documentBody;
  private DATE_FORMAT = 'DD/MM/YYYY HH:mm';
  private anchura: number = 80;
  type: PrintableType;

  constructor(
    private translate: TranslateService
  ) {
    this.documentBody = new jspdf('p', 'mm', [this.anchura * 2.83, 275 * 2.83]);
    //this.documentBody = new jspdf('p', 'mm', 'a4');
    this.type = PrintableType.CASH_CLOSURE_REPORT;
  }

  paint(options: CashClosureReportOptions): Printer {
    //WOIT Modificar para incluir tarea 7669
    let posicion = 5;
    const item = Object.assign({}, options);
    this.documentBody.setFontSize(8);
    this.documentBody.text(
      this.translate.instant('DESGLOSE_VENTAS'),
      20,
      posicion
    );
    posicion += 5;
    this.documentBody.text('__ __ __ __ __ __ __ __ __ __ __ __ __ __ __ __ __ __', 5, posicion);
    posicion += 5;
    this.documentBody.text(moment().format('YYYY/MM/DD HH:mm:ss'), 47, posicion);
    // this.documentBody.text(this.calculaFecha(item.data.FechaInicio), 40, posicion);
    // this.documentBody.text(this.calculaHora(item.data.FechaCierre), 60, posicion);
    posicion += 5;
    this.documentBody.text(
      `${this.translate.instant('CAJA_ID')}: ${item.data.CajaId}`,
      10,
      posicion
    );
    // this.documentBody.text(item.data.CajaId, 20, posicion);
    this.documentBody.text(
      `${this.translate.instant('CODIGO_CAJA')}: ${item.data.CajaCodigo}`,
      40,
      posicion
    );
    // this.documentBody.text(item.data.CajaCodigo, 50, posicion);
    posicion += 10;
    this.documentBody.text(this.translate.instant('USUARIOS'), 10, posicion);
    posicion += 5;
    if (item.data.ListadoCajaUsuarios) {
      item.data.ListadoCajaUsuarios.forEach(element => {
        this.documentBody.text(element.UsuarioId, 10, posicion);
        this.documentBody.text(element.UsuarioNombre, 20, posicion);
        if (element.TurnoId) {
          this.documentBody.text(element.TurnoId, 40, posicion);
        }
        posicion += 5;
        this.documentBody.text(element.FechaHoraInicio, 10, posicion);
        this.documentBody.text(element.FechaHoraFin, 40, posicion);
        posicion += 2;
        this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
        posicion += 5;
      });
    }
    posicion += 5;
    this.documentBody.text(this.translate.instant('JUSTIFICANTE_TPV'), 10, posicion);
    this.documentBody.text(item.data.TPVId, 30, posicion);
    this.documentBody.text(item.data.TPVNombre, 50, posicion);
    posicion += 15;
    this.documentBody.text(this.translate.instant('CANTIDAD'), 5, posicion);
    this.documentBody.text(this.translate.instant('PRODUCTO'), 20, posicion);
    this.documentBody.text(this.translate.instant('JUSTIFICANTE_IMPORTE'), 60, posicion);
    posicion += 2;
    this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
    posicion += 5;
    if (item.data.ListadoCajaProductos) {
      item.data.ListadoCajaProductos.forEach(element => {
        // this.documentBody.text(element.ProductoCantidad, 10, posicion);
        // this.documentBody.text(element.ProductoNombre, 20, posicion);
        // this.documentBody.setFont('Courier');
        // this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat(element.ProductoImporteTotal.replace(',','.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
        // //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(element.ProductoImporteTotal), this.anchura - 68) + ' €', 50, posicion);
        // this.documentBody.setFont('Helvetica');
        // posicion += 5;

        this.documentBody.text(element.ProductoCantidad, 10, posicion);
        let ArrayItemImpresion: string[] = [];
        ArrayItemImpresion = this.getArrayNombreImpresion(element.ProductoNombre, ArrayItemImpresion, 21);
        this.documentBody.text(ArrayItemImpresion[0], 20, posicion);
        this.documentBody.setFont('Courier');
        this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat(element.ProductoImporteTotal.replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
        this.documentBody.setFont('Helvetica');
        posicion += 5;
        if (ArrayItemImpresion.length > 1) {
          for (let i = 1; i < ArrayItemImpresion.length; i++) {
            this.documentBody
              .text(ArrayItemImpresion[i], 20, posicion);
            posicion += 5;

          }
        }


      });
    }
    this.documentBody.text('_____________________________________________________', 0, posicion);
    posicion += 5;
    this.documentBody.text(this.translate.instant('TOTALCONIVA'), 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat(item.data.ImporteTotal.replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteTotal), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 15;
    if (item.data.ImporteCA && !this.esCero(item.data.ImporteCA)) {
      this.documentBody.text(this.translate.instant('METALICO'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat(item.data.ImporteCA.replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteCA), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteCC && !this.esCero(item.data.ImporteCC)) {
      this.documentBody.text(this.translate.instant('TARJETA'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat(item.data.ImporteCC.replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteCC), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteTR && !this.esCero(item.data.ImporteTR)) {
      this.documentBody.text(this.translate.instant('TRANSFERENCIA'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteTR).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteTR), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteDP && !this.esCero(item.data.ImporteDP)) {
      this.documentBody.text(this.translate.instant('APLAZADO'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteDP).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteDP), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteIN && !this.esCero(item.data.ImporteIN)) {
      this.documentBody.text(this.translate.instant('INTERNET'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteIN).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteIN), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteTM && !this.esCero(item.data.ImporteTM)) {
      this.documentBody.text(this.translate.instant('JUSTIFICANTE_TARJETA_MONEDERO'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteTM).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteTM), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImportePR && !this.esCero(item.data.ImportePR)) {
      this.documentBody.text(this.translate.instant('PREPAGO'), 10, posicion);
      this.documentBody.setFont('Courier');
      //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImportePR), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImportePR).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }

    //Nuevos campos Online
    // if (item.data.ListadoCajaUsuarios && item.data.ListadoCajaUsuarios.length > 0 ) //Va a ser solo si es por usuario
    // { //Se encarga la API
    if (item.data.ImporteOnlineCA && !this.esCero(item.data.ImporteOnlineCA)) {
      this.documentBody.text(this.translate.instant('EFECTIVO_ONLINE'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteOnlineCA).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteOnlineCA), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteOnlineCC && !this.esCero(item.data.ImporteOnlineCC)) {
      this.documentBody.text(this.translate.instant('TARJETA_ONLINE'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteOnlineCC).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteOnlineCC), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteOnlinePP && !this.esCero(item.data.ImporteOnlinePP)) {
      this.documentBody.text('PayPal', 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteOnlinePP).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteOnlinePP), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteOnlinePS && !this.esCero(item.data.ImporteOnlinePS)) {
      this.documentBody.text('Phone & Sell', 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteOnlinePS).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteOnlinePS), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteOnlineTR && !this.esCero(item.data.ImporteOnlineTR)) {
      this.documentBody.text(this.translate.instant('TRANSFERENCIA_ONLINE'), 10, posicion);
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteOnlineTR).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteOnlineTR), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    if (item.data.ImporteOnlineDP && !this.esCero(item.data.ImporteOnlineDP)) {
      this.documentBody.text(
        `${this.translate.instant('APLAZADO')} ${this.translate.instant('ONLINE')}`,
        10,
        posicion
      );
      this.documentBody.setFont('Courier');
      this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteOnlineDP).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
      //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteOnlineDP), this.anchura - 68) + ' €', 50, posicion);
      this.documentBody.setFont('Helvetica');
      posicion += 5;
    }
    //}

    this.documentBody.text('_____________________________________________________', 0, posicion);
    posicion += 5;
    this.documentBody.text(this.translate.instant('CAMBIO_INICIAL'), 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteInicial).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteInicial), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 5;
    this.documentBody.text(this.translate.instant('APORTE_CAJA'), 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteIntroducido).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteIntroducido), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 5;
    this.documentBody.text(this.translate.instant('RETIRADA_CAJA'), 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteRetirado).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    // this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteRetirado), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 5;
    this.documentBody.text('_____________________________________________________', 0, posicion);
    posicion += 5;
    this.documentBody.text(this.translate.instant('TOTAL_ARQUEO_MANUAL'), 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.ImporteSupervisor).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.ImporteSupervisor), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 5;
    this.documentBody.text(this.translate.instant('DIFERENCIA_ARQUEO_MANUAL'), 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.Diferencia).replace(',', '.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.Diferencia), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 30;
    this.documentBody.text(this.translate.instant('FIRMA_ENTREGA_COLABORADOR'), 10, posicion);
    posicion += 2;
    this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
    posicion += 30;
    this.documentBody.text(this.translate.instant('FIRMA_RECIBI_RESPONSABLE'), 10, posicion);
    posicion += 2;
    this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
    posicion += 30;
    this.documentBody.text(this.translate.instant('COMENTARIOS'), 10, posicion);
    posicion += 2;
    this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
    /*this.documentBody.text('Prepago', 20, posicion);
    this.documentBody.text(item.data.ImporteIN + ' €', 40, posicion);
    posicion += 5;*/
    const pageCount = this.documentBody.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.documentBody.setPage(i);
      this.documentBody.setFontSize(10);
      this.documentBody.text(10, 290, moment().format(this.DATE_FORMAT));
      this.documentBody.text(
        190,
        290,
        this.documentBody.internal.getCurrentPageInfo().pageNumber +
        '/' +
        pageCount
      );
    }
    return this;
  }

  /*paint(options: CashClosureReportOptions): Printer {
    this.documentBody.text('Horst', 10, 5);
    this.documentBody.autoTable({
      theme: 'plain',
      tableHeight: 20,
      headStyles: { textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table0'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table1'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      tableWidth: 80,
      columnStyles: { 0: { fontStyle: 'bold' }, textColor: [0, 0, 0] },
      margin: { right: 100 },
      html: '#my-table2'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: 30,
      tableWidth: 102,
      columnStyles: {
        0: { cellWidth: 62 },
        1: { cellWidth: 40 }
      },
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        halign: 'center'
      },
      margin: { left: 94 },
      html: '#my-table3'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table4'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table5'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' }
      },
      html: '#my-table6'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table7'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table8'
    });
    /*this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table9'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' }
      },
      html: '#my-table10'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table11'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table12'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table13'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' }
      },
      html: '#my-table14'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table15'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table16'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      html: '#my-table17'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontStyle: 'bold', fontSize: 9, textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'center', valign: 'center' },
        2: { cellWidth: 21, halign: 'center', valign: 'center' },
        3: { cellWidth: 21, halign: 'center', valign: 'center' },
        4: { cellWidth: 21, halign: 'center', valign: 'center' },
        5: { cellWidth: 21, halign: 'center' },
        6: { cellWidth: 21, halign: 'center', valign: 'center' }
      },
      html: '#my-table18'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table19'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table20'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fontSize: 9, fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 50, valign: 'center', fontStyle: 'bold' },
        1: { cellWidth: 21, halign: 'right', valign: 'center' },
        2: { cellWidth: 21, halign: 'right', valign: 'center' },
        3: { cellWidth: 21, halign: 'right', valign: 'center' },
        4: { cellWidth: 21, halign: 'right', valign: 'center' },
        5: { cellWidth: 21, halign: 'right', valign: 'center' },
        6: { cellWidth: 21, halign: 'right', valign: 'center' }
      },
      html: '#my-table21'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table22'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      startY: this.documentBody.autoTable.previous.finalY,
      styles: { fillColor: [240, 240, 240] },
      columnStyles: {
        0: { fontStyle: 'bold', textColor: [0, 0, 0] },
        1: { cellWidth: 20, halign: 'right' }
      },
      html: '#my-table23'
    });
    this.documentBody.autoTable({
      theme: 'grid',
      tableHeight: 20,
      startY: this.documentBody.autoTable.previous.finalY + 1,
      headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
      columnStyles: { 0: { fontStyle: 'bold' } },
      html: '#my-table24'
    });
    this.documentBody.autoTable({
      theme: 'plain',
      tableHeight: 60,
      columnStyles: {
        0: { fontStyle: 'bold', halign: 'center' },
        1: { fontStyle: 'bold', halign: 'center' }
      },
      html: '#my-table25'
    });

    const pageCount = this.documentBody.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.documentBody.setPage(i);
      this.documentBody.setFontSize(10);
      this.documentBody.text(10, 290, moment().format(this.DATE_FORMAT));
      this.documentBody.text(
        190,
        290,
        this.documentBody.internal.getCurrentPageInfo().pageNumber +
          '/' +
          pageCount
      );
    }
    return this;
  }*/

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    // connection.print(this.documentBody, this.type);
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(this.documentBody),
        'documento'
      );
    } else {
      this.documentBody.save('cierre.pdf');
    }
  }

  calculaHora(fecha) {
    const hora = fecha.split(' ')[1];
    const [h, m, s] = hora.split(':');
    return h + ':' + m;
  }

  calculaFecha(fecha) {
    return fecha.split(' ')[0];
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private esCero(valor: string): boolean {
    var respuesta = true;
    valor = valor.replace(/\./g, ''); // quitamos los puntos
    valor = valor.replace(/,/g, '.'); // cambiamos comas por puntos
    let numero: number = parseFloat(valor);
    if (numero && numero != 0) {
      respuesta = false;
    }
    return respuesta;
  }

  private parsearFloatEnString(valor: string): string {
    //Esta funcion puede presentar problemas si nos encontramos con una devolucion de la API en formato numerico 
    //#,###,##0.00
    var decimales = 0;
    var respuesta = '';
    var last = valor.lastIndexOf('.') >= 0 ? valor.lastIndexOf('.') : valor.lastIndexOf(',');
    decimales = valor.length - (valor.length - (valor.length - (last > 0 ? last + 1 : valor.length)));
    decimales = decimales < 0 ? 0 : decimales;
    var separador = last >= 0 ? valor[last] : ',';
    if (separador == ',') {
      valor = valor.replace(/\./g, ''); // quitamos los puntos
      valor = valor.replace(/,/g, '.'); // cambiamos comas por puntos
    } else if (separador = '.') {
      valor = valor.replace(/,/g, '.'); // quitamos las comas
    }
    respuesta = parseFloat(valor).toFixed(decimales);

    return respuesta;
  }

  private justificarPosicion(valor: string, tamano: number): string {
    var respuesta = valor;
    var prefijo = tamano - valor.length;
    if (prefijo > 0) {
      respuesta = new Array(prefijo + 1).join(' ') + respuesta;
    }
    return respuesta;
  }

  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    let negativo = false;
    if (number.substring(0, 1) === '-') {
      negativo = true;
      number = number.substring(1);
    }
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        newInteger = negativo ? '-' + newInteger : newInteger;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        newInteger = negativo ? '-' + newInteger : newInteger;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      newInteger = negativo ? '-' + newInteger : newInteger;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    number = negativo ? '-' + number : number;
    return number + d;
  }

// << Yaribel 20210114 Creamos funcion para controlar la longitud de la descripcion y hacer salto de linea
  getArrayNombreImpresion(NombreImpresion: string, result: string[], cantidad?) {
    let limite = cantidad || 27;
    let variable = 4;
    if (NombreImpresion.length > limite && NombreImpresion.length > (limite + variable)) {
      const miRexp = /\s[a-zA-Z]|-|\+|\//;

      let separador;
      while (!separador && (limite + variable) < NombreImpresion.length) {
        separador = miRexp.exec(NombreImpresion.substring(limite - variable, limite + variable));
        if (separador && separador.length > 0) {
          limite = limite - variable + separador.index + 1;
        }
        variable++;
      }
      const primerNombreImpresion: string = NombreImpresion.substring(0, limite);
      result.push(primerNombreImpresion);
      NombreImpresion = NombreImpresion.substring(limite);
      result = this.getArrayNombreImpresion(NombreImpresion, result);
    } else {
      result.push(NombreImpresion);
    }
    return result;
  }
}
