import { Component, OnInit, OnDestroy } from '@angular/core';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { takeWhile, take, mergeMap, map } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ToastrService } from 'ngx-toastr';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { ImprimirComponent } from '../imprimir/imprimir.component';
import { PrintService } from 'src/app/shared/services/print.service';
import { MyCurrencyPipe } from 'src/app/shared/pipes/myCurrency.pipe';
import * as moment from 'moment';
import 'moment/min/locales';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ticketing-consultar-dinero-caja',
  templateUrl: './consultar-dinero-caja.component.html',
  styleUrls: ['./consultar-dinero-caja.component.scss']
})
export class ConsultarDineroCajaComponent implements OnInit, OnDestroy {
  datosInforme;
  usuarios;
  productosVenta;
  comentarios;
  currentDate = moment().format('YYYY-MM-DD HH:mm');
  arqueoConfirmado;
  sePuedeArquear = false;
  cambio = {
    cambioInicial: 0,
    cambioIntroducido: 0,
    cambioRetirado: 0,
    ventasEfectivo: undefined,
    cambioResultante: 0
  };
  locale;

  private alive = true;
  consultarEfectivoCajaSub: Subscription;

  arqueoForm: FormGroup;

  constructor(
    private turnoService: TurnoService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private toast: ToastrService,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private printService: PrintService,
    private currency: MyCurrencyPipe
  ) {}

  ngOnInit() {
    this.locale = this.translate.getLocale() || 'es-ES';
    this.consultarEfectivoCajaSub = this.turnoService
      .consultarEfectivoCaja()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        this.cambio.ventasEfectivo = this.parse(res.DatosResult.ImporteTotalCaja || '0');
        this.turnoService.setCambioValue(res.DatosResult);
      });
    this.arqueoForm = this.formBuilder.group({
      efectivo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      comentario: [
        '',
        Validators.compose([Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9á-ý\\u00f1\\u00d1\\s]*$')])
      ]
    });
  }

  parse(cantidad) {
    return Number.parseFloat(cantidad.replace(',', '.'));
  }

  ngOnDestroy() {
    this.alive = false;
    this.permisosService.reducir();
    this.consultarEfectivoCajaSub.unsubscribe();
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.arqueoForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  arqueoParcial() {
    if (this.checkFormIsInvalid()) {
      return;
    }
    const efectivo = Number.parseFloat(this.arqueoForm.controls['efectivo'].value) || 0;
    this.comentarios = this.arqueoForm.controls['comentario'].value || '';
    this.turnoService
      .arqueoParcial(efectivo, this.comentarios)
      .pipe(
        mergeMap(res => {
          return this.turnoService.generarInformeArqueo(this.turnoService.cajaValue.CajaId).pipe(
            map((datosInforme: any) => {
              return { ...res, datosInforme: datosInforme.DatosResult };
            })
          );
        })
      )
      .subscribe((res: any) => {
        if (!res.DatosResult) {
          this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
            toastComponent: ErrorToast,
            timeOut: 5000
          });
          return;
        }
        this.datosInforme = res.datosInforme;
        this.usuarios = res.datosInforme.ListadoCajaUsuarios;
        this.productosVenta = res.datosInforme.ListadoCajaProductos;
        this.turnoService
          .consultarEfectivoCaja()
          .pipe(take(1))
          .subscribe((r: any) => {
            this.arqueoForm.reset();
            this.cambio.ventasEfectivo = this.parse(r.DatosResult.ImporteTotalCaja || '0');
            this.turnoService.setCambioValue(res.DatosResult);
            this.toast.success(
              '',
              this.translate.instant('ARQUEO_PARCIAL_EFECTUADO'),
              {
              toastComponent: SuccessToast,
              timeOut: 5000
            });
            setTimeout(() => {
              this.printService.printRandomArchingReport();
            }, 3000);
          });
      });
  }

  checkFormIsInvalid() {
    if (this.arqueoForm && this.arqueoForm.invalid) {
      return true;
    }
    return false;
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false
    };
    return this.permisosService.puedeAccionar(this.user.currentUserValue, accion, conf);
  }
  calculaPrecioUnitario(total, cantidad) {
    const unidad =
      Number.parseFloat(total.replace(',', '.')) / Number.parseFloat(cantidad.replace(',', '.'));
    return this.currency.transform(unidad, ',', 2);
  }
  calculaTotalNumVentas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + parseInt(producto.ProductoCantidad);
    });
    return total;
  }

  calculaTotalImporteVentas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number.parseFloat(producto.ProductoImporteTotal.replace(',', '.'));
    });
    return this.currency.transform(total, ',', 2);
  }

  calculaHora(fecha) {
    return fecha.split(' ')[1];
  }

  calculaFecha(fecha) {
    return fecha.split(' ')[0];
  }
}
